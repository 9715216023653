import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { priceFormatter } from '@utils/priceFormatter';
import { formatDate } from '@utils/date-utils/formatDate';
import { IOrderDetailsResult } from '@model/order';
import ProductDetailsCard from './ProductDetailsCard';
import { getChipColor } from '@utils/chipColor';

interface ProductTableRowsProps {
  products: IOrderDetailsResult['products'];
  selectedProductId: number | string | null;
  onRowClick: (productId: number | string) => void;
}

const ProductTableRows: React.FC<ProductTableRowsProps> = ({
  products,
  selectedProductId,
  onRowClick,
}) => (
  <>
    {products.map((product) => (
      <React.Fragment key={product.productId}>
        <TableRow
          onClick={() => onRowClick(product.productId)}
          style={{ cursor: 'pointer' }}
        >
          <TableCell component="th" scope="row">
            {product.articleId}
          </TableCell>
          <TableCell>{product.name}</TableCell>
          <TableCell>{priceFormatter(product.unitPrice)}</TableCell>
          <TableCell>{product.quantity}</TableCell>
          <TableCell>
            <Chip label={product.status} style={getChipColor(product.status)} />
          </TableCell>
          <TableCell>{priceFormatter(product.totalDiscount)}</TableCell>
          <TableCell>
            {product.expectedDeliveryDate
              ? formatDate(product.expectedDeliveryDate)
              : 'N/A'}
          </TableCell>
          <TableCell>{product.consignmentNumber}</TableCell>
          <TableCell>
            <IconButton onClick={() => onRowClick(product.productId)}>
              <ExpandMoreIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        {selectedProductId === product.productId && (
          <TableRow>
            <TableCell colSpan={12}>
              <ProductDetailsCard product={product} />
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    ))}
  </>
);

export default ProductTableRows;
