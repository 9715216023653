import React from 'react';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Not Found</h1>
      <p>
        The page you are looking for doesn't exist or you do not have permission
        to access it.
      </p>
    </div>
  );
};

export default NotFound;
