import { IProfile, IUser } from '@model/customer';
import { fetchAllCustomerData } from '../../CustomerService';
import { enqueueSnackbar } from 'notistack';
import appState from '@/state';
import { batch } from '@preact/signals';
import { BrandKey } from '@model/brand';

interface FetchCustomerDataParams {
  customerDetails: IUser;
  profileDetails: IProfile;
  customerBrand: BrandKey;
  t: (key: string) => string;
}

export const fetchAndDispatchCustomerData = async ({
  customerDetails,
  profileDetails,
  customerBrand,
  t,
}: FetchCustomerDataParams) => {
  if (!customerDetails?.uid && !profileDetails?.trackingId && !customerBrand) {
    enqueueSnackbar(t('messages.searchCustomerFirst'));
    return;
  }

  try {
    const {
      cardDetails,
      walletDetails,
      walletTransactions,
      memberOffers,
      punchCards,
      receipts,
    } = await fetchAllCustomerData(
      customerDetails.email,
      customerDetails.uid,
      profileDetails.trackingId,
      customerBrand,
    );

    batch(() => {
      appState.loyalty.creditCard.data.value = cardDetails?.data || undefined;
      appState.loyalty.wallet.data.value = walletDetails?.data || undefined;
      appState.loyalty.walletTransactions.data.value =
        walletTransactions?.data || undefined;
      appState.loyalty.memberOffers.data.value =
        memberOffers?.data || undefined;
      appState.loyalty.punchCards.data.value = punchCards?.data || undefined;
      appState.loyalty.receipts.data.value = receipts?.data || undefined;
    });
  } catch (error: unknown) {
    console.error('Failed to fetch customer data:', error);
    enqueueSnackbar(t('errors.customerContextSearch'));
  }
};
