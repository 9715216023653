export const priceFormatter = (value: number) => {
  return (
    value.toLocaleString('da-DK', {
      style: 'decimal',
      currency: 'DKK',
      minimumFractionDigits: 2,
    }) + ' kr.'
  );
};

export const currencyFormatter = (
  value: number,
  currencyCode: string,
): string => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatCurrency = (locale: string, currency: string, value: number) => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      ...options,
    }).format(value);
  };
  switch (currencyCode) {
    case 'DKK':
      return formatCurrency('da-DK', 'DKK', value);
    case 'PLN':
      return formatCurrency('pl-PL', 'PLN', value);
    case 'EUR':
      return formatCurrency('de-DE', 'EUR', value);
    case 'POINTS':
      return `${value}\u00A0POINTS`;
    default:
      return new Intl.NumberFormat().format(value);
  }
};
