import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import useAppSection from '@utils/useAppSection';
import { enqueueSnackbar } from 'notistack';
import { BrandKey } from '@model/brand';

interface BrandSelectProps {
  selectedBrand: BrandKey;
  setSelectedBrand: (brand: BrandKey) => void;
}

export const BrandSelect: React.FC<BrandSelectProps> = ({
  selectedBrand,
  setSelectedBrand,
}) => {
  const { t } = useTranslation();
  const { supportedBrands } = useAppSection();

  if (!supportedBrands.some((brand) => brand.key === selectedBrand)) {
    enqueueSnackbar(
      t('messages.nonSupportedBrand', {
        brand: selectedBrand,
        defaultBrand: supportedBrands[0].label,
      }),
    );
    setSelectedBrand(supportedBrands[0].key);
  }

  return (
    <Select
      sx={{
        height: '2.5rem',
        marginLeft: '-0.95rem',
        '& .MuiOutlinedInput-notchedOutline': {
          borderLeft: 'none',
          borderBottom: 'none',
          borderTop: 'none',
        },
      }}
      value={selectedBrand}
      onChange={(e) => setSelectedBrand(e.target.value as BrandKey)}
    >
      {supportedBrands.map((brand) => (
        <MenuItem key={brand.key} value={brand.key}>
          {brand.label}
        </MenuItem>
      ))}
    </Select>
  );
};
