import React from 'react';
import CardContent from '@mui/material/CardContent';
import { shipmentAddressFormatter } from '@utils/addressFormatter';
import { emptyValueReplacement } from '@utils/emptyValueReplacement';
import {
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '../../mui-helpers/customComponentStyles';
import { IShipmentAddress } from '@model/nshift';

interface AddressDetailsProps {
  title: string;
  address: IShipmentAddress;
}

const AddressDetails: React.FC<AddressDetailsProps> = ({ title, address }) => (
  <StyledBorderCard variant="outlined">
    <CardContent sx={{ flex: 1 }}>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>
        {emptyValueReplacement(address.name)}
        <br />
        {shipmentAddressFormatter(address)}
        <br />
        Telefon: {emptyValueReplacement(address.phone)}
        <br />
        Mobil: {emptyValueReplacement(address.mobile)}
        <br />
        Email: {emptyValueReplacement(address.email)}
      </StyledDescription>
    </CardContent>
  </StyledBorderCard>
);

export default AddressDetails;
