import {
  IOrderDetailsResult,
  IOrderResult,
  IGetOrderDetailsParams,
  IGetOrderListParams,
} from '@model/order';
import { trackPromise } from 'react-promise-tracker';
import axios, { AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IPostCustomerRemark, ICustomerRemarkPost } from '@model/remark';
import { appConfig } from '@/appConfig';

interface IOrderService {
  getShortOrderDetails(params: IGetOrderDetailsParams): Promise<IOrderResult[]>;

  getFullOrderDetails(
    params: IGetOrderDetailsParams,
  ): Promise<IOrderDetailsResult>;

  getOrderList(params: IGetOrderListParams): Promise<IOrderResult[]>;

  postCustomerRemark(params: IPostCustomerRemark): Promise<void>;
}

/**
 * Retrieves short order details based on the provided parameters.
 *
 * @param params - The parameters required to get the order details.
 * @returns A promise that resolves to an array of order results containing
 *          orderId, price, email, phone, and orderTime.
 * @throws Will reject the promise if an error occurs while fetching the order details.
 */
export class OrderService implements IOrderService {
  public getShortOrderDetails = async (
    params: IGetOrderDetailsParams,
  ): Promise<IOrderResult[]> => {
    try {
      const orderDetails = await this.getFullOrderDetails(params);
      const orderObject: IOrderResult = {
        orderId: orderDetails.orderNumber,
        price: orderDetails.total - orderDetails.totalDiscount,
        email: orderDetails.billingAddress.email,
        phone: orderDetails.billingAddress.mobile,
        orderTime: new Date(orderDetails.orderDate),
      };
      return Promise.resolve([orderObject]);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Retrieves the full details of an order based on the provided parameters.
   *
   * @param params - The parameters required to fetch the order details.
   * @param params.brand - The brand associated with the order. Defaults to 'bilka' if not provided.
   * @param params.orderId - The unique identifier of the order.
   *
   * @returns A promise that resolves to the order details.
   *
   * @throws Throws an error if the request fails or times out.
   */
  public getFullOrderDetails = async (
    params: IGetOrderDetailsParams,
  ): Promise<IOrderDetailsResult> => {
    const brand = params.brand ? params.brand : 'bilka';
    const options: AxiosRequestConfig = {
      method: `GET`,
      url: `${appConfig.sgApiNextOrdersUrlV2}${brand}/orders/${params.orderId}`,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': `application/json`,
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(options).then((response) => {
        return response.data;
      }),
      'full-order-details',
    );
  };

  /**
   * Retrieves a list of orders based on the provided parameters.
   *
   * @param params - The parameters required to fetch the order list.
   * @returns A promise that resolves to an array of order results.
   */
  public getOrderList = async (
    params: IGetOrderListParams,
  ): Promise<IOrderResult[]> => {
    const url = `${appConfig.sgApiNextOrdersUrlV1}${params.brand}/orders?${params.identifier}=${params.input}`;
    const options: AxiosRequestConfig = {
      method: `GET`,
      url: url,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': `application/json`,
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(options).then((response) => {
        return response.data;
      }),
      'order-list',
    );
  };

  /**
   * Posts a customer remark(comments from the customer) to the specified order.
   *
   * @param params - The parameters required to post a customer remark.
   * @returns A promise that resolves when the customer remark has been posted.
   * @throws Will throw an error if the request fails.
   *
   */
  public postCustomerRemark = async (
    params: IPostCustomerRemark,
  ): Promise<void> => {
    const options: AxiosRequestConfig<ICustomerRemarkPost> = {
      method: 'POST',
      url: `${appConfig.sgApiNextOrdersUrlV1}${params.brand}/orders/${params.orderId}/remarks/`,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': 'application/json',
      },
      data: {
        erpOrderNumber: params.erpOrderNumber,
        customerRemarks: params.customerRemarks,
      },
      timeout: 5000,
    };
    return trackPromise(
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          enqueueSnackbar(`Kunne ikke oprette kommentar på ordren.  ${error}`, {
            variant: 'error',
            autoHideDuration: 4000,
          });
          throw error;
        }),
    );
  };
}
