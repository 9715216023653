export const containsSearchTerm = (obj: any, searchTerm: string): boolean => {
  if (obj === null || obj === undefined) {
    return false;
  }
  if (typeof obj === 'string') {
    return obj.toLowerCase().includes(searchTerm.toLowerCase());
  }
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        const value = obj[key];
        if (containsSearchTerm(value, searchTerm)) {
          return true;
        }
      }
    }
  }
  return false;
};
