import type { JSX } from 'react';
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { emptyValueReplacement } from '@utils/emptyValueReplacement';
import { useTranslation } from 'react-i18next';

export interface ICustomerDetailRow {
  [key: string]: string | number | boolean | null | undefined | JSX.Element;
}

interface CustomTableProps {
  headers: string[];
  rows: ICustomerDetailRow[];
}

const CustomTable: React.FC<CustomTableProps> = ({ headers, rows }) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} elevation={3}>
      <Table sx={{ minWidth: 650 }} aria-label="customer details table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                key={header}
                sx={{ color: 'text.secondary', fontSize: '1.2rem' }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const uniqueKey = Object.values(row).join('-');
            return (
              <TableRow key={uniqueKey}>
                {Object.entries(row).map(([key, value]) => (
                  <TableCell key={key} sx={{ fontSize: '1rem' }}>
                    {React.isValidElement(value)
                      ? value
                      : emptyValueReplacement(
                          value as string | number | boolean | null | undefined,
                          t,
                        )}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
