export enum SearchType {
  EMAIL = 'email',
  MEMBER_ID = 'memberId',
  GIGYA_ID = 'gigyaId',
  ORDER_ID = 'orderId',
}

export const checkInputType = (input: string | number): SearchType => {
  if (typeof input !== 'number') {
    input = input.replace(/\s/g, '');
  }

  if (!isNaN(input as number)) {
    return SearchType.ORDER_ID;
  }
  return SearchType.EMAIL;
};

/**
 * Checks the type of the input within loyalty search.
 * The input can be a member ID, email or gigya ID.
 * @param {string | number} input - The input to check.
 * @returns {string} - The type of the input
 */
export const checkLoyaltyInputType = (input: string | number): SearchType => {
  if (typeof input !== 'number') {
    input = input.replace(/\s/g, '');
  }
  if (!isNaN(input as number)) {
    return SearchType.MEMBER_ID;
  } else if (validateEmail(input as string)) {
    return SearchType.EMAIL;
  } else {
    return SearchType.GIGYA_ID;
  }
};

/**
 * Checks if the input is a valid email.
 * @param {string} email - The email to check.
 * @returns {boolean} - True if the email is valid, false otherwise.
 */
const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

/**
 * Sanitizes the input by removing unwanted characters and trimming it.
 * @param {string} input - The input to sanitize.
 * @returns {string} - The sanitized input.
 */
export const sanitizeInput = (input: string): string => {
  const sanitized = input
    .replace(/[&<>"'/]/g, '')
    .replace(/\u00A0/g, ' ')
    .replace(/\t/g, '');
  return sanitized.trim();
};
