import { Brand, BrandKey, BrandProperties } from '@model/brand';

import bilkaLogo from '@assets/images/logos/bilka_logo.svg';
import brLogo from '@assets/images/logos/br_logo.svg';
import carlsJrLogo from '@assets/images/logos/carls_jr_logo.svg';
import fotexLogo from '@assets/images/logos/fotex_logo.svg';
import nettoLogo from '@assets/images/logos/netto_logo.svg';
import sallingLogo from '@assets/images/logos/salling_logo.svg';

const brandProperties: readonly BrandProperties[] = [
  {
    key: 'bilka',
    label: 'Bilka',
    logo: bilkaLogo,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpblkMembershipNr',
        signupDateKey: 'cpblkSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: true,
  },

  {
    key: 'carlsjr',
    label: "Carl's Jr.",
    logo: carlsJrLogo,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpcjrMembershipNr',
        signupDateKey: 'cpcjrSignupDate',
      },
      areMemberOffersBurnable: true,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: false,
  },

  {
    key: 'foetex',
    label: 'Føtex',
    logo: fotexLogo,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpfotMembershipNr',
        signupDateKey: 'cpfotSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: true,
  },

  {
    key: 'nettode',
    label: 'Netto DE',
    logo: nettoLogo,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpndeMembershipNr',
        signupDateKey: 'cpndeSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: false,
  },

  {
    key: 'nettodk',
    label: 'Netto DK',
    logo: nettoLogo,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpndkMembershipNr',
        signupDateKey: 'cpndkSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: false,
  },

  {
    key: 'nettopl',
    label: 'Netto PL',
    logo: nettoLogo,

    loyalty: false,
    orders: false,
  },

  {
    key: 'salling',
    label: 'Salling',
    logo: sallingLogo,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpsalMembershipNr',
        signupDateKey: 'cpsalSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: false,
      isProductDataAvailable: false,
    },

    orders: false,
  },

  {
    key: 'br',
    label: 'BR',
    logo: brLogo,

    loyalty: false,
    orders: true,
  },
] as const;

export const brands = brandProperties.map(
  (brand: BrandProperties) => new Brand(brand),
);

export const getBrand = (brandKey: BrandKey): Brand => {
  const brand = brands.find((brand) => brand.key === brandKey);
  if (!brand) {
    throw new Error(`Brand with key '${brandKey}' not found`);
  }
  return brand;
};

export const getSupportedBrandsForLoyalty = (): Brand[] => {
  return brands.filter((brand) => brand.isSupportedForLoyalty);
};

export const getSupportedBrandsForOrders = (): Brand[] => {
  return brands.filter((brand) => brand.isSupportedForOrders);
};

export const DEFAULT_BRAND = brands[0];

const brandConfig = {
  brands,
  DEFAULT_BRAND,
  getBrand,
  getSupportedBrandsForLoyalty,
  getSupportedBrandsForOrders,
};

export default brandConfig;
