import { appConfig } from '@/appConfig';
import { getAdToken } from './getAdToken';

/**
 * Retrieves common headers required for loyalty-related requests.
 *
 * @returns {Promise<Object>} A promise that resolves to an object containing the common headers.
 * @throws {Error} If there is an issue obtaining the authorization token.
 */
export const getCommonHeaders = async () => {
  const token = await getAdToken();
  return {
    Authorization: `Bearer ${token}`,
    'SG-APIM-ROUTER': 'B',
    'SG-APIM-IDENTIFIER-ID': appConfig.sgApimIdentifierId,
  };
};
