import React from 'react';

interface NewsletterListProps {
  newsletters: string[];
}

const NewsletterList: React.FC<NewsletterListProps> = ({ newsletters }) => {
  return (
    <ul style={{ listStyleType: 'none' }}>
      {newsletters.map((newsletter, index) => (
        <li key={`${index}-${newsletter}`}>{newsletter}</li>
      ))}
    </ul>
  );
};

export default NewsletterList;
