import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';

interface DateRangePickerProps {
  initialStartDate?: Dayjs;
  onDateChange?: (startDate: Dayjs | null, endDate: Dayjs | null) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  initialStartDate,
  onDateChange,
}) => {
  /**
   * Since we cannot use the date range picker from MUI because of the pro license, this component ties two date pickers together.
   * When the start date is selected, the start date and the end date picker is opened. Days before the start date are disabled in the end date picker.
   * The end date picker closes when a date is selected or when clicking outside of it.
   */
  const currentDate = dayjs();
  const initialStart = initialStartDate || currentDate;

  const [startDate, setStartDate] = useState<Dayjs | null>(initialStart);
  const [endDate, setEndDate] = useState<Dayjs | null>(initialStart);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);
  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date);
    if (date) {
      // Open the end date picker programmatically after a start date is selected
      setOpenEndDate(true);
      if (onDateChange) {
        onDateChange(date, endDate);
      }
    } else {
      setEndDate(null);
      if (onDateChange) {
        onDateChange(null, null);
      }
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    setEndDate(date);
    setOpenEndDate(false);
    if (onDateChange) {
      onDateChange(startDate, date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="da">
      <Box display="flex" gap={1}>
        <DatePicker
          label="Startdato"
          value={startDate}
          onChange={handleStartDateChange}
          timezone="UTC"
        />
        {/*Adding onClose and onOpen to programmatically control the end date picker:
        Automatically opens the end date picker after selecting the start date.
        Allows the user to close the end date picker without selecting a date.
        Prevents user frustration by allowing them to open and close the end date picker as needed.*/}
        <DatePicker
          label="Slutdato"
          value={endDate}
          onChange={handleEndDateChange}
          minDate={startDate || currentDate}
          open={openEndDate}
          onClose={() => setOpenEndDate(false)}
          onOpen={() => setOpenEndDate(true)}
          timezone="UTC"
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
