import { msalInstance } from '@/index';

/**
 * Retrieves the Azure AD token for the currently active account.
 *
 * Checks if there is an active account and throws an error if none is found.
 * It then checks the expiration of the ID token claims and forces a token refresh if the token is expired.
 * If the token is not expired, it attempts to silently acquire a token.
 *
 * @returns {Promise<string>} The ID token of the active account.
 * @throws {Error} If no active account is found.
 */
export const getAdToken = async (): Promise<string> => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.',
    );
  }

  const idTokenClaims = account.idTokenClaims;
  if (idTokenClaims && idTokenClaims.exp) {
    const forceRefresh = idTokenClaims.exp * 1000 < new Date().valueOf();
    if (forceRefresh) {
      console.info('Getting token silently with force refresh');
      const refreshedResponse = await msalInstance.acquireTokenSilent({
        scopes: ['User.Read'],
        account: account,
        forceRefresh: true,
      });
      return refreshedResponse.idToken;
    }
  }

  const response = await msalInstance.acquireTokenSilent({
    scopes: ['User.Read'],
    account: account,
  });

  return response.idToken;
};
