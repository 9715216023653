import React, { useEffect, useState } from 'react';
import { deleteLoyaltyCustomer } from '@/service/CustomerService';
import { Delete, KeyboardArrowDown } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Button from '@mui/material/Button';
import Loader from '../mui-helpers/Loader';
import CustomTable, { ICustomerDetailRow } from '../mui-helpers/CustomTable';
import { formatDate } from '@utils/date-utils/formatDate';
import LogoImage from '../loyalty/LogoImage';
import NewsletterList from '../loyalty/NewsletterList';
import CreditCardList from '../loyalty/cards/CreditCardList';
import ConfirmationDialog from '../mui-helpers/ConfirmationDialog';
import { hasRole } from '@utils/roleUtils';
import { RoleNames } from '@utils/RoleNames';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@hooks/useUserRoles';
import { emptyValueReplacement } from '@utils/emptyValueReplacement';
import NoContentMessage from '../mui-helpers/NoContentMessage';
import ProgramMemberships from '../loyalty/programDetails/ProgramMemberships';
import { useLocation } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import CustomerIdTooltip from '@components/profile/CustomerIdTooltip';
import appState from '@/state';
import useAppSection, { AppSection } from '@utils/useAppSection';
import { getBrand } from '@/brandConfig';
import HasWalletValue from '@components/profile/HasWalletValue';

interface IProfileRow extends ICustomerDetailRow {
  Brand: JSX.Element;
  'Membership Id': JSX.Element | string | null;
  'Has Wallet': JSX.Element | string | boolean;
  'Use Wallet For Next Purchase': boolean;
  'Created At': string;
  'Last Locked At': string;

  [key: string]: any;
}

const CustomerDetails: React.FC = () => {
  const appSection = useAppSection();
  const userRoles = useUserRoles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const location = useLocation();

  const customerBrand = appState.customer.brand.value;
  const customerDetails = appState.customer.details.value;
  const profileDetails = appState.customer.profile.value;
  const customerAddress = appState.customer.address.value;
  const creditCard = appState.loyalty.creditCard.data.value;

  const [profileRows, setProfileRows] = useState<IProfileRow[]>([]);

  const { t } = useTranslation();

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const handleDeleteCustomer = async () => {
    try {
      if (customerBrand && customerDetails?.uid) {
        await deleteLoyaltyCustomer(customerBrand, customerDetails.uid);
        handleCloseDialog();
      }
    } catch (error) {
      enqueueSnackbar(t('errors.failedToDeleteCustomer'));
    }
  };

  const headers = [
    t('customerDetails.headers.name'),
    t('customerDetails.headers.address'),
    t('customerDetails.headers.email'),
    t('customerDetails.headers.newsletter'),
    t('customerDetails.headers.registered'),
    t('customerDetails.headers.verified'),
    t('customerDetails.headers.active'),
  ];

  const customerName = [customerDetails?.firstName, customerDetails?.lastName]
    .filter((v) => v)
    .join(' ');

  const getNewsletterValue = () => {
    const newsletters = customerDetails?.newsletter
      .filter((n) => n.isConsentGranted)
      .map((n) => n.name);

    return Array.isArray(newsletters) && newsletters.length > 0 ? (
      <NewsletterList newsletters={newsletters} />
    ) : (
      'N/A'
    );
  };

  const rows = customerDetails
    ? [
        {
          Name: customerName,
          Address: customerAddress,
          Email: customerDetails.email,
          Newsletter: getNewsletterValue(),
          Registered: formatDate(customerDetails.registered),
          Verified: customerDetails.isVerified,
          Active: customerDetails.isActive,
        },
      ]
    : [];

  const profileHeaders = [
    t('profile.headers.brand'),
    t('profile.headers.membershipId'),
    t('profile.headers.hasWallet'),
    t('profile.headers.useWalletForNextPurchase'),
    t('profile.headers.createdAt'),
    t('profile.headers.lastLockedAt'),
  ];

  /**
   * Retrieves and formats the sign-up date for a customer's brand-specific program.
   * @returns {string} The formatted sign-up date or a localized string indicating that the information is not available.
   */
  const getBrandSignUpDate = (): string => {
    if (customerDetails?.accountData && customerBrand) {
      const brand = getBrand(customerBrand);
      const customerProgram = customerDetails.accountData.customerProgram;
      const correspondingBrandSignUp = brand.customerProgramSignupDate;
      const signupDate = customerProgram?.[correspondingBrandSignUp] ?? null;
      return signupDate ? formatDate(signupDate) : t('generic.notAvailable');
    } else return t('generic.notAvailable');
  };

  useEffect(() => {
    if (profileDetails && customerBrand) {
      setProfileRows([
        {
          Brand: (
            <LogoImage
              src={getBrand(customerBrand).logo}
              alt={`${customerBrand} logo`}
            />
          ),
          'Membership Id': (
            <span>
              {emptyValueReplacement(profileDetails.membershipId)}{' '}
              <CustomerIdTooltip
                membershipId={profileDetails.membershipId}
                trackingId={profileDetails.trackingId}
                cdcId={profileDetails.cdcId}
              />
            </span>
          ),
          'Has Wallet': (
            <HasWalletValue
              isWalletExisting={profileDetails.hasWallet}
              isWalletBlocked={profileDetails.isWalletBlocked}
              walletLastBlockedAt={profileDetails.walletLastBlockedAt}
            />
          ),
          'Use Wallet For Next Purchase': profileDetails.useWalletOnPurchase,
          'Created At': getBrandSignUpDate(),
          'Last Locked At': formatDate(profileDetails.lastLockedAt),
        },
      ]);
    } else {
      setProfileRows([]);
    }
  }, [customerBrand, profileDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDeletionAllowed = () => {
    return (
      hasRole(userRoles, RoleNames.GDPR) &&
      customerDetails &&
      appSection.isAppSection(AppSection.Loyalty)
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      {appState.loyalty.isFetchingMemberData.value && (
        <Loader usePromiseTrackerCheck={false} />
      )}
      {!customerDetails && !profileDetails ? (
        <NoContentMessage infoMessage={t('messages.noCustomerData')} />
      ) : (
        <>
          {(rows.length > 0 || profileRows.length > 0) && (
            <Box sx={{ mb: 3, '& > :first-of-type': { mb: 2 } }}>
              {rows.length > 0 && <CustomTable headers={headers} rows={rows} />}
              {profileDetails && (
                <>
                  <CustomTable headers={profileHeaders} rows={profileRows} />
                  <CreditCardList creditCard={creditCard || []} />
                </>
              )}
            </Box>
          )}
          {customerDetails && profileDetails && customerDetails.accountData && (
            <ProgramMemberships accountData={customerDetails.accountData} />
          )}
          {customerDetails && customerDetails.organizations.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Accordion defaultExpanded component={Paper} elevation={3}>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDown />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>{t('customerDetails.organizations')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {customerDetails.organizations.map((org) => (
                      <React.Fragment key={org.bpid}>
                        <ListItem>
                          <ListItemIcon>
                            <ApartmentIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={org.orgName}
                            secondary={`${t('customerDetails.sapCustomerId')}: ${org.bpid}`}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          {isDeletionAllowed() && (
            <Button
              variant="contained"
              color="error"
              onClick={handleOpenDialog}
              startIcon={<Delete />}
            >
              {t('button.deleteCustomer')}
            </Button>
          )}
        </>
      )}
      <ConfirmationDialog
        isOpen={isDialogOpen}
        title={t('confirmationDialog.title')}
        description={t('confirmationDialog.description', {
          customerName: customerName,
          customerEmail: customerDetails?.email,
          customerBrandName: customerBrand,
        })}
        onConfirm={handleDeleteCustomer}
        onCancel={handleCloseDialog}
      />
    </Box>
  );
};

export default CustomerDetails;
