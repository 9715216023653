import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { IShipmentEvent } from '@model/nshift';
import { formatDate } from '@utils/date-utils/formatDate';
import {
  StyledDescription,
  StyledTitle,
} from '../../mui-helpers/customComponentStyles';

interface ShipmentEventsProps {
  events: IShipmentEvent[];
}

const ShipmentEvents: React.FC<ShipmentEventsProps> = ({ events }) => (
  <Card variant="outlined">
    <CardContent>
      <StyledTitle>Forsendelseshændelser</StyledTitle>
      {events.length > 0 ? (
        events.map((event, index) => (
          <StyledDescription key={`${index}-${event.statusName}`}>
            {formatDate(event.date)}:{event.translatedStatusName} (
            {event.statusName})
          </StyledDescription>
        ))
      ) : (
        <StyledDescription>Ingen hændelser</StyledDescription>
      )}
    </CardContent>
  </Card>
);

export default ShipmentEvents;
