import React, { useMemo } from 'react';
import { IAccountData } from '@model/customer';
import { useTranslation } from 'react-i18next';
import {
  StyledDescription,
  StyledHeader,
} from '../../mui-helpers/customComponentStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { fetchCustomerDetailsWithBrand } from '@/service/CustomerService';
import { fetchAndDispatchCustomerData } from '@/service/request-helpers/loyalty/customerDataUtils';
import ProgramCard from './ProgramCard';
import { enqueueSnackbar } from 'notistack';
import { batch } from '@preact/signals';
import appState from '@/state';
import { BrandKey } from '@model/brand';
import { AppSection, getSupportedBrands } from '@utils/useAppSection';

interface CustomerProgramProps {
  accountData: IAccountData;
}

const ProgramMemberships: React.FC<CustomerProgramProps> = ({
  accountData,
}) => {
  const { customerProgram } = accountData;
  const { t } = useTranslation();

  const customerDetails = appState.customer.details.value;
  const profileDetails = appState.customer.profile.value;
  const customerBrand = appState.customer.brand.value;
  const customerEmail = appState.customer.email.value;

  /**
   * Reduces the list of brands to an array of brand details where the customer has a membership Id or signup date for the brand.
   */
  const customerBrandDetails = useMemo(() => {
    return getSupportedBrands(AppSection.Loyalty)
      .filter(
        (brand) =>
          customerProgram && customerProgram[brand.customerProgramMembershipNr],
      )
      .map((brand) => ({
        brand,
        membershipNr: customerProgram![brand.customerProgramMembershipNr],
        signupDate: customerProgram![brand.customerProgramSignupDate],
      }));
  }, [customerProgram]);

  const renderNoPrograms = () => {
    if (customerProgram === null || customerBrandDetails.length === 0) {
      return (
        <StyledDescription sx={{ padding: '1rem' }}>
          {t('customerProgram.noPrograms')}
        </StyledDescription>
      );
    }
    return null;
  };

  const fetchCustomerProfile = async (brandKey: BrandKey) => {
    if (!customerEmail || !brandKey || !profileDetails?.trackingId) {
      enqueueSnackbar(t('messages.searchCustomerFirst'));
      return;
    }

    //saving the customer email, then resetting states in customer context so it can be fetched again.
    const tempCustomerEmail = customerEmail;
    appState.customer.reset();

    try {
      const loyaltyResponse = await fetchCustomerDetailsWithBrand(
        tempCustomerEmail,
        brandKey,
      );

      batch(() => {
        appState.customer.brand.value = brandKey;
        appState.customer.details.value = loyaltyResponse.user;
        appState.customer.profile.value = loyaltyResponse.profile;
      });
      handleFetchCustomerData();
    } catch (error) {
      console.error('Failed to fetch customer data:', error);
      enqueueSnackbar(t('errors.failedToFetchByEmail'));
    }
  };

  const handleFetchCustomerData = async () => {
    if (customerDetails?.uid && profileDetails?.trackingId && customerBrand) {
      try {
        await fetchAndDispatchCustomerData({
          customerDetails,
          profileDetails,
          customerBrand,
          t,
        });
      } catch (error) {
        console.error(
          'An error occurred while fetching and dispatching customer data:',
          error,
        );
        enqueueSnackbar(t('errors.customerDetailsFetch'));
      }
    } else {
      enqueueSnackbar(t('errors.customerDetailsFetch'));
    }
  };

  if (!appState) {
    return null;
  }

  const renderProgramMemberships = () => (
    <Grid container paddingX={4} gap={4}>
      {customerBrandDetails.map((customerBrandDetail, index) => (
        <ProgramCard
          customerBrandDetails={customerBrandDetail}
          index={index}
          fetchCustomerProfile={fetchCustomerProfile}
          key={`${customerBrandDetail.brand.key}-${index}`}
        />
      ))}
    </Grid>
  );

  return (
    <Accordion sx={{ my: 2 }} defaultExpanded component={Paper} elevation={3}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="program-memberships"
      >
        <StyledHeader gutterBottom>{t('customerProgram.title')}</StyledHeader>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {renderProgramMemberships()}
          {renderNoPrograms()}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default ProgramMemberships;
