import { RetryRequestNames } from '@model/RetryRequestNames';

interface ErrorInstance {
  error: any;
  errorCode: number;
  message: string;
  path: string;
  timestamp: string;
}

const isRequiredErrorInstance = (error: any): error is ErrorInstance =>
  error && typeof error === 'object' && typeof error.errorCode === 'number';

const shouldNotRetry = (errorCode: number): boolean =>
  [400, 401, 402, 403, 404, 405, 409, 410, 418, 422].includes(errorCode);

/**
 * Retries a request function a specified number of times if it fails. The retry
 * is based if the error is a CustomError instance and the error code is not in the
 * list of error codes that should not be retried.
 * e.g. We don't want to retry a not found call.
 *
 * @template T - The type of the data returned by the request function.
 * @param {() => Promise<T>} fn - The request function to be retried.
 * @param {RetryRequestNames} requestName - The name of the request for logging or tracking purposes.
 * @param {number} [retries=1] - The number of times to retry the request if it fails.
 * @returns An object containing the data, failure status, error code, request name, and number of attempts.
 */
export const retryRequest = async <T>(
  fn: () => Promise<T>,
  requestName: RetryRequestNames,
  retries: number = 1,
): Promise<{
  data: T | null;
  failed: boolean;
  errorCode?: number;
  requestName?: RetryRequestNames;
  attempts: number;
}> => {
  for (let attempt = 0; attempt <= retries; attempt++) {
    try {
      const data = await fn();
      return { data, failed: false, requestName, attempts: attempt + 1 };
    } catch (error: any) {
      if (isRequiredErrorInstance(error) && !shouldNotRetry(error.errorCode)) {
        if (attempt === retries) {
          return {
            data: null,
            failed: true,
            errorCode: error.errorCode,
            requestName,
            attempts: attempt + 1,
          };
        }
      }
    }
  }
  return { data: null, failed: true, requestName, attempts: retries };
};
