import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Loader from '../mui-helpers/Loader';
import { useTranslation } from 'react-i18next';
import { TabIds, TabNames } from '@model/LoyaltyTabsConfig';
import Tabs from '@mui/material/Tabs';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Tab, { TabProps } from '@mui/material/Tab';
import WarningAmber from '@mui/icons-material/WarningAmber';
import Tooltip from '@mui/material/Tooltip';
import appState from '@/state';
import { RequestStatus } from '@model/requestStatus';
import { useLoyaltyStateLoader } from '@/state/loyalty/useLoyaltyStateLoader';

const useTabPropInitialization = (
  selectCustomerFirstMessage: string,
  retryAvailableMessage: string,
) => {
  return (
    label: string,
    isDataMissing: boolean,
    missingDataTooltip: string,
    ...requests: RequestStatus[]
  ) => {
    const tabProps: Partial<TabProps> = { style: { pointerEvents: 'auto' } };

    const isPending = requests.some((request) => !request.isFetched);
    const isError = requests.some((request) => request.isError);

    let tooltip: string | undefined = isDataMissing
      ? missingDataTooltip
      : undefined;

    if (isPending) {
      tabProps.disabled = true;
      tooltip = selectCustomerFirstMessage;
    } else if (isDataMissing) {
      tabProps.disabled = true;
    }

    if (isError) {
      tabProps.disabled = true;
      tabProps.icon = <WarningAmber fontSize="small" />;
      tabProps.iconPosition = 'end';
      tooltip = retryAvailableMessage;
    }

    if (tooltip) {
      tabProps.label = (
        <Tooltip title={tooltip}>
          <span>{label}</span>
        </Tooltip>
      );
    } else {
      tabProps.label = <span>{label}</span>;
    }

    return tabProps;
  };
};

const Loyalty = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string | boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const handleChange = (_: React.SyntheticEvent, newTabId: string) => {
    navigate(`/loyalty/${newTabId}${location.search}`);
  };

  useLoyaltyStateLoader();

  const getTabProps = useTabPropInitialization(
    t('customTabPanel.searchCustomerFirst'),
    t('customTabPanel.retryAvailable'),
  );

  if (appState.loyalty.customer.request.value.isFetching) {
    return <Loader loadingMessage={t('messages.loading')} />;
  }

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Tabs variant="scrollable" value={activeTab} onChange={handleChange}>
        <Tab
          disabled={false}
          label={<span>{t(TabNames.CUSTOMER_PROFILE)}</span>}
          value={TabIds.CUSTOMER_PROFILE}
        />
        {appState.loyalty.useWallet.value && (
          <Tab
            {...getTabProps(
              t(TabNames.WALLET),
              !appState.loyalty.wallet.data.value,
              t('customTabPanel.noWallet'),
              appState.loyalty.wallet.request.value,
              appState.loyalty.walletTransactions.request.value,
            )}
            value={TabIds.WALLET}
          />
        )}
        <Tab
          {...getTabProps(
            t(TabNames.MEMBER_OFFERS),
            !Array.isArray(
              appState.loyalty.memberOffers.data.value?.memberOffers,
            ) ||
              appState.loyalty.memberOffers.data.value?.memberOffers.length ===
                0,
            t('customTabPanel.noOffers'),
            appState.loyalty.memberOffers.request.value,
          )}
          value={TabIds.MEMBER_OFFERS}
        />
        <Tab
          {...getTabProps(
            t(TabNames.PUNCH_CARDS),
            !Array.isArray(
              appState.loyalty.punchCards.data.value?.memberPunchCards,
            ) ||
              appState.loyalty.punchCards.data.value?.memberPunchCards
                .length === 0,
            t('customTabPanel.noPunchCards'),
            appState.loyalty.punchCards.request.value,
          )}
          value={TabIds.PUNCH_CARDS}
        />
        <Tab
          {...getTabProps(
            t(TabNames.RECEIPTS),
            !Array.isArray(appState.loyalty.receipts.data.value) ||
              appState.loyalty.receipts.data.value.length === 0,
            t('customTabPanel.noReceipts'),
            appState.loyalty.receipts.request.value,
          )}
          value={TabIds.RECEIPTS}
        />
      </Tabs>
      <Box p={3}>
        <Outlet
          context={{
            creditCard: appState.loyalty.creditCard.data.value,
            setActiveTab,
          }}
        />
      </Box>
    </Box>
  );
};

export default Loyalty;
