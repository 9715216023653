import { IAddress } from '@model/order';
import { IShipmentAddress } from '@model/nshift';
import { IStore } from '@model/receipt';

export const addressFormatter = (address: IAddress | IStore) => {
  if ('sapStoreId' in address) {
    // Handle IStore type for Loyalty Receipt
    return `${address.addressLine}, ${address.zipCode} ${address.city} ${address.country}`;
  } else {
    // Handle IAddress type
    return `${address.street}, ${address.additionalStreetInfo ? address.additionalStreetInfo + ', ' : ''}${address.postalCode} ${address.city}`;
  }
};

export const shipmentAddressFormatter = (address: IShipmentAddress) => {
  return `${address.street}, ${address.postCode} ${address.city}, ${address.countryCode}`;
};
