import React from 'react';
import { priceFormatter } from '@utils/priceFormatter';
import { formatDate } from '@utils/date-utils/formatDate';
import { emptyValueReplacement } from '@utils/emptyValueReplacement';
import {
  ScrollableCardContent,
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '../../../mui-helpers/customComponentStyles';
import { IProduct } from '@model/order';
import Typography from '@mui/material/Typography';
import { TextConstants } from '@utils/TextConstant';

interface ProductInformationProps {
  product: IProduct;
}

const ProductInformation: React.FC<ProductInformationProps> = ({ product }) => {
  const PRODUCT_INFORMATION = TextConstants.PRODUCT_HEADERS.PRODUCT_INFORMATION;

  return (
    <StyledBorderCard variant="outlined">
      <ScrollableCardContent sx={{ flex: 1 }}>
        <StyledTitle>Produkt detaljer</StyledTitle>
        <StyledDescription variant="body2" color="text.secondary">
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.NAME}:</strong>{' '}
            {emptyValueReplacement(product.name)}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.CONSIGNMENT}:</strong>{' '}
            {emptyValueReplacement(product.consignmentNumber)}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.ARTICLE_ID}:</strong>{' '}
            {emptyValueReplacement(product.articleId)}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.STATUS}:</strong>{' '}
            {emptyValueReplacement(product.status)}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.REQUESTED_QUANTITY}:</strong>{' '}
            {emptyValueReplacement(product.requestedQuantity)}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.QUANTITY}:</strong>{' '}
            {emptyValueReplacement(product.quantity)}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.TOTAL_DISCOUNT}:</strong>{' '}
            {emptyValueReplacement(priceFormatter(product.totalDiscount))}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.TOTAL_PRICE}:</strong>{' '}
            {emptyValueReplacement(priceFormatter(product.totalPrice))}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.UNIT_PRICE}:</strong>{' '}
            {emptyValueReplacement(priceFormatter(product.unitPrice))}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.INVOICE_DATE}:</strong>{' '}
            {product.invoiceDate ? formatDate(product.invoiceDate) : 'N/A'}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.INVOICE_ID}:</strong>{' '}
            {emptyValueReplacement(product.invoiceId)}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.NET_PRICE}:</strong>{' '}
            {emptyValueReplacement(priceFormatter(product.netPrice))}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.REJECTION_STATUS}:</strong>{' '}
            {emptyValueReplacement(product.rejectionStatus)}
          </Typography>
          <Typography variant="body2" mb={1}>
            <strong>{PRODUCT_INFORMATION.ITEM_CATEGORY}:</strong>{' '}
            {emptyValueReplacement(product.itemCategory)}
          </Typography>
        </StyledDescription>
      </ScrollableCardContent>
    </StyledBorderCard>
  );
};

export default ProductInformation;
