import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccordionDetails from '@mui/material/AccordionDetails';
import { StyledTitle } from './customComponentStyles';
import styled from '@mui/system/styled';

interface ScrollableAccordionProps {
  title: string;
  defaultExpanded?: boolean;
  children: React.ReactNode;
}

/**custom card content styles for showing product history allows the card
 *to become scrollable, so it does not extend to take too much space* */
const ScrollableAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  maxHeight: '30vh',
  overflowY: 'auto',
}));

const ScrollableAccordion: React.FC<ScrollableAccordionProps> = ({
  title,
  defaultExpanded = false,
  children,
}) => {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      component={Paper}
      elevation={3}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        <StyledTitle>{title}</StyledTitle>
      </AccordionSummary>
      <ScrollableAccordionDetails>{children}</ScrollableAccordionDetails>
    </Accordion>
  );
};

export default ScrollableAccordion;
