import React from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

interface PunchesRequiredProps {
  punchesRequired: number;
  punchesAcquired: number;
}

const PunchesRequired: React.FC<PunchesRequiredProps> = ({
  punchesRequired,
  punchesAcquired,
}) => {
  const filledStamps = Math.min(punchesAcquired, punchesRequired);

  return (
    <Box sx={{ textAlign: 'center', p: 2 }}>
      <Grid container spacing={1} justifyContent="center" marginBottom={2}>
        {[...Array(punchesRequired)].map((_, index) => (
          <Grid item key={index}>
            {index < filledStamps ? (
              <VerifiedIcon color="primary" fontSize="small" />
            ) : (
              <NewReleasesIcon color="disabled" fontSize="small" />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PunchesRequired;
