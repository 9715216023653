export const TextConstants = {
  ORDER_DETAILS_TAB_NAMES: ['Produktoversigt', 'Produktsporing', 'Kundeprofil'],
  PRODUCT_HEADERS: {
    ARTICLE_ID: 'Artikel ID',
    PRODUCT_NAME: 'Produktnavn',
    PRICE_DKK: 'Pris (DKK)',
    QUANTITY: 'Antal/Stk',
    ORDER_STATUS: 'Ordre Status',
    TOTAL_DISCOUNT: 'Rabat (DKK)',
    EXPECTED_DELIVERY_DATE: 'Forventet Leveringsdato',
    SHIPMENT_NUMBER: 'Forsendelsesnummer',
    PRODUCT_INFORMATION: {
      NAME: 'Navn',
      CONSIGNMENT: 'Consignment',
      ARTICLE_ID: 'Artikel ID',
      STATUS: 'Status',
      REQUESTED_QUANTITY: 'Anmodet antal',
      QUANTITY: 'Antal',
      TOTAL_DISCOUNT: 'Total rabat',
      TOTAL_PRICE: 'Total pris',
      UNIT_PRICE: 'Enhedspris',
      INVOICE_DATE: 'Faktura dato',
      INVOICE_ID: 'Faktura ID',
      NET_PRICE: 'Nettopris',
      REJECTION_STATUS: 'Afvisningsstatus',
      ITEM_CATEGORY: 'Varekategori',
    },
  },
  MESSAGES: {
    loading: 'Indlæser',
    noData: 'ingen information',
  },
};
