import React from 'react';

export const parseHtmlStringToReact = (htmlString: string): React.ReactNode => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const convertNodeToReact = (node: Node): React.ReactNode => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }
    if (node.nodeType !== Node.ELEMENT_NODE) {
      return null;
    }
    const element = node as Element;
    const children = Array.from(element.childNodes).map(convertNodeToReact);
    return React.createElement(element.tagName.toLowerCase(), {}, ...children);
  };
  return Array.from(doc.body.childNodes).map(convertNodeToReact);
};
