import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { usePromiseTracker } from 'react-promise-tracker';
import { TextConstants } from '@utils/TextConstant';

interface LoaderProps {
  loadingMessage?: string;
  usePromiseTrackerCheck?: boolean;
  area?: string;
}

const Loader: React.FC<LoaderProps> = ({
  loadingMessage = TextConstants.MESSAGES.loading,
  usePromiseTrackerCheck = true,
  area,
}) => {
  const { promiseInProgress } = usePromiseTracker({ area });

  return (
    <>
      {(!usePromiseTrackerCheck || promiseInProgress) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <CircularProgress />
          <Typography component="div">{loadingMessage} &hellip;</Typography>
        </Box>
      )}
    </>
  );
};

export default Loader;
