import { trackPromise } from 'react-promise-tracker';
import {
  InShiftShipmentsResult,
  IGetNShiftShipmentsParams,
} from '@model/nshift';
import axios from 'axios';
import { appConfig } from '@/appConfig';
import { enqueueSnackbar } from 'notistack';

interface INShiftService {
  getNShiftShipments(
    params: IGetNShiftShipmentsParams,
  ): Promise<InShiftShipmentsResult>;
}

/**
 * Retrieves NShift shipments based on the provided parameters.
 *
 * @param params - The parameters required to get NShift shipments.
 * @returns A promise that resolves to the NShift shipments result.
 * @throws Throws an error if the request fails.
 */
export class NShiftService implements INShiftService {
  public getNShiftShipments = async (
    params: IGetNShiftShipmentsParams,
  ): Promise<InShiftShipmentsResult> => {
    const options = await this.setOptions(params);
    return trackPromise(
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          enqueueSnackbar(`Failed to get shipments: ${error}`, {
            variant: 'error',
            autoHideDuration: 4000,
          });
          throw error;
        }),
    );
  };

  //  if the dates are not provided, then a search for the last 31 days will be performed
  public setOptions = async (params: IGetNShiftShipmentsParams) => {
    let url = `${appConfig.sgApiNshiftShipments}${params.query}`;
    if (params.startDate && params.endDate) {
      url += `?startDate=${params.startDate}&endDate=${params.endDate}`;
    }
    return {
      method: `GET`,
      url: url,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': `application/json`,
      },
      timeout: 5000,
    };
  };
}
