import { AccountInfo } from '@azure/msal-browser';

export const extractUserRoles = (accounts: AccountInfo[]): string[] => {
  const account = accounts[0];
  return account?.idTokenClaims?.roles || [];
};

export const hasRole = (roles: string[], role: string): boolean => {
  return roles.includes(role);
};
