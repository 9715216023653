import axios, { AxiosRequestConfig } from 'axios';
import { appConfig } from '@/appConfig';
import { trackPromise } from 'react-promise-tracker';
import { IGetMemberOffers, OffersData } from '@model/offer';
import { getCommonHeaders } from './request-helpers/loyalty/getLoyaltyCommonHeaders';
import { performADRequest } from './request-helpers/apiHelper';
import { ICreditCardDetail } from '@components/loyalty/cards/ICreditCardDetail';
import { fetchReceipts } from './ReceiptService';
import { retryRequest } from './request-helpers/retryRequest';
import { fetchPunchCards } from './TradeDriverService';
import { fetchWalletDetails, fetchWalletTransactions } from './WalletService';
import { RetryRequestNames } from '@model/RetryRequestNames';
import { CustomError } from './CustomError';
import { ICustomerDetailsByBrand, IUser } from '@model/customer';
import { BrandKey } from '@model/brand';

/**
 * Fetches customer details based on the provided email address.
 *
 * @param {string} customerEmail - The email address of the customer to look up.
 * @returns {Promise<IUser>} A promise of type IUser.
 */
export const fetchCustomerDetails = async (
  customerEmail: string,
): Promise<IUser> => {
  const options: AxiosRequestConfig = {
    method: `GET`,
    url: `${appConfig.sgApiBaseUrlV1}customer-service/customers/lookup?email=${customerEmail}`,
    headers: {
      Authorization: `Bearer ${appConfig.sgBearer}`,
      'Content-Type': `application/json`,
    },
    timeout: 5000,
  };
  return trackPromise(
    axios(options).then((response) => {
      return response.data;
    }),
  );
};

/**
 * Fetches customer details associated with the specified brand.
 *
 * @param customerEmail - The customer's email.
 * @param brand - The selected brand.
 * @returns A promise that resolves to the customer details for the specified brand.
 */
export const fetchCustomerDetailsWithBrand = async (
  customerEmail: string,
  brand: string,
): Promise<ICustomerDetailsByBrand> => {
  const encodedEmail = encodeURIComponent(customerEmail);
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/profile?email=${encodedEmail}`;
  const headers = await getCommonHeaders();
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers,
    timeout: 5000,
  };

  return trackPromise(
    axios(url, options).then((response) => {
      return response.data;
    }),
  );
};

/**
 * Fetches member offers based on various criteria.
 *
 * @param {Object} params - The parameters for fetching member offers.
 * @param {string} params.trackingId - The tracking ID of the member.
 * @param {string} params.brand - The brand associated with the member.
 * @param {boolean} [params.isActive] - Whether the offer is active.
 * @param {boolean} [params.isRedeemable] - Whether the offer can be redeemed.
 * @param {Date} [params.validAt=new Date()] - The date at which the offer is valid.
 * @param {string} [params.validFrom] - The start date of the offer validity.
 * @param {string} [params.validUntil] - The end date of the offer validity.
 * @param {string} [params.type] - The type of the offer.
 * @param {string} [params.offerTacticType] - The tactic type of the offer.
 * @param {string[]} [params.offerTactic] - The tactics of the offer.
 * @returns {Promise<OffersData>} A promise that resolves to the offers data.
 */
export const fetchMemberOffers = async ({
  trackingId,
  brand,
  isActive,
  isRedeemable,
  validAt = new Date(),
  validFrom,
  validUntil,
  type,
  offerTacticType,
  offerTactic,
}: IGetMemberOffers): Promise<OffersData> => {
  let queryParams = new URLSearchParams();
  if (isActive !== undefined) queryParams.append('isActive', String(isActive));
  if (validAt) queryParams.append('validAt', validAt.toISOString());
  if (isRedeemable !== undefined)
    queryParams.append('isRedeemable', String(isRedeemable));
  if (validFrom) queryParams.append('validFrom', validFrom);
  if (validUntil) queryParams.append('validUntil', validUntil);
  if (type) queryParams.append('type', type);
  if (offerTacticType) queryParams.append('offerTacticType', offerTacticType);
  if (offerTactic)
    offerTactic.forEach((t) => queryParams.append('offerTactic', t));

  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${trackingId}/offers?${queryParams}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch card details`);
  }
};

/**
 * Fetches the credit card details for a customer.
 *
 * @param cdcId - The SAP Customer Data Cloud ID.
 * @returns A promise that resolves to an array of credit card details.
 * @throws CustomError if the request fails.
 */
export const fetchCardDetails = async (
  cdcId: string,
): Promise<ICreditCardDetail[]> => {
  const url = `${appConfig.sgApiBaseUrlV1}customers/${cdcId}/cards`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch card details`);
  }
};

const createRetryFunction = (
  fetchFunction: () => Promise<any>,
  retryRequestName: RetryRequestNames,
  area: string,
) => {
  return () =>
    retryRequest(() => trackPromise(fetchFunction(), area), retryRequestName);
};

/**
 * Fetches all customer data including card details, wallet details, wallet transactions, member offers, punch cards, and receipts.
 * If any request fails, it will be retried based on the retry logic defined in the retry functions.
 *
 * @param {string} customerEmail - The email address associated with the customer.
 * @param {string} cdcId - The CDC ID associated with the customer.
 * @param {string} trackingId - The tracking ID associated with the customer.
 * @param {string} brand - The brand associated with the customer.
 * @returns An object containing all the fetched customer data and retry functions for failed requests.
 */
export const fetchAllCustomerData = async (
  customerEmail: string,
  cdcId: string,
  trackingId: string,
  brand: BrandKey,
) => {
  try {
    const customerDetailsFn = createRetryFunction(
      () => fetchCustomerDetailsWithBrand(customerEmail, brand),
      RetryRequestNames.CustomerDetails,
      'customer-details-area',
    );
    const cardDetailsFn = createRetryFunction(
      () => fetchCardDetails(cdcId),
      RetryRequestNames.CardDetails,
      'card-details-area',
    );
    const walletDetailsFn = createRetryFunction(
      () => fetchWalletDetails(trackingId, brand),
      RetryRequestNames.WalletDetails,
      'wallet-details-area',
    );
    const walletTransactionsFn = createRetryFunction(
      () => fetchWalletTransactions(trackingId, brand),
      RetryRequestNames.WalletTransactions,
      'wallet-transactions-area',
    );
    const memberOffersFn = createRetryFunction(
      () => fetchMemberOffers({ trackingId, brand }),
      RetryRequestNames.MemberOffers,
      'updated-offers-area',
    );
    const punchCardsFn = createRetryFunction(
      () => fetchPunchCards(trackingId, brand, new Date()),
      RetryRequestNames.PunchCards,
      'receipt-list-area',
    );
    const receiptsFn = createRetryFunction(
      () => fetchReceipts(cdcId, brand, 100),
      RetryRequestNames.Receipts,
      'receipt-list-area',
    );

    const results = await Promise.allSettled([
      cardDetailsFn(),
      walletDetailsFn(),
      walletTransactionsFn(),
      memberOffersFn(),
      punchCardsFn(),
      receiptsFn(),
    ]);

    const failedRequests = results
      .filter(
        (result) =>
          result.status === 'fulfilled' &&
          result.value.failed &&
          result.value.attempts > 1,
      )
      .map((result) =>
        result.status === 'fulfilled' ? result.value.requestName : null,
      )
      .filter(Boolean);

    return {
      cardDetails: results[0].status === 'fulfilled' ? results[0].value : null,
      walletDetails:
        results[1].status === 'fulfilled' ? results[1].value : null,
      walletTransactions:
        results[2].status === 'fulfilled' ? results[2].value : null,
      memberOffers: results[3].status === 'fulfilled' ? results[3].value : null,
      punchCards: results[4].status === 'fulfilled' ? results[4].value : null,
      receipts: results[5].status === 'fulfilled' ? results[5].value : null,
      failedRequests,
      retryFunctions: {
        [RetryRequestNames.CustomerDetails]: customerDetailsFn,
        [RetryRequestNames.CardDetails]: cardDetailsFn,
        [RetryRequestNames.WalletDetails]: walletDetailsFn,
        [RetryRequestNames.WalletTransactions]: walletTransactionsFn,
        [RetryRequestNames.MemberOffers]: memberOffersFn,
        [RetryRequestNames.PunchCards]: punchCardsFn,
        [RetryRequestNames.Receipts]: receiptsFn,
      },
    };
  } catch (error) {
    throw new Error(`Failed to fetch all customer data: ${error}`);
  }
};

/**
 * Deletes a loyalty customer by unsubscribing them from the loyalty program.
 *
 * @param brand - The brand associated with the customer.
 * @param gigyaId - The customer's Gigya ID.
 * @returns A promise that resolves when the customer has been successfully unsubscribed.
 * @throws An error if the request to delete the loyalty customer fails.
 */
export const deleteLoyaltyCustomer = async (brand: string, gigyaId: string) => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/unsubscribe/${gigyaId}`;
  try {
    return await performADRequest('POST', url);
  } catch (error) {
    throw new Error(`Failed to delete loyalty: ${error}`);
  }
};

/**
 * Fetches the loyalty profile of a customer by their membership ID.
 *
 * @param memberId - The membership ID of the customer.
 * @returns A promise that resolves to the customer's details by brand.
 * @throws Will throw an error if the request fails.
 */
export const fetchLoyaltyProfileByMembershipId = async (
  memberId: string,
): Promise<ICustomerDetailsByBrand> => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/loyalty/member/${memberId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch member by member ID:`);
  }
};

/**
 * Fetches the loyalty profile of a customer by their Gigya ID.
 *
 * @param gigyaId - The customer's Gigya ID.
 * @param brand - The loyalty brand being searched for.
 * @returns A promise that resolves to the customer's details by brand.
 * @throws Will throw an error if the request fails.
 */
export const fetchLoyaltyProfileByGigyaId = async (
  gigyaId: string,
  brand: string,
): Promise<ICustomerDetailsByBrand> => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/profile/users/${gigyaId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch member by gigya ID:`);
  }
};
