import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { KeyboardArrowDown } from '@mui/icons-material';
import CreditCard from './CreditCard';
import { ICreditCardDetail } from './ICreditCardDetail';
import { StyledTitle } from '../../mui-helpers/customComponentStyles';
import { useTranslation } from 'react-i18next';

interface CreditCardListProps {
  creditCard?: ICreditCardDetail[] | null;
}

const CreditCardList: React.FC<CreditCardListProps> = ({ creditCard }) => {
  const { t } = useTranslation();

  const generateCards = () => {
    if (!Array.isArray(creditCard) || creditCard.length === 0) {
      return (
        <StyledTitle sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1.2rem' }}>
          {t('creditCard.noData')}
        </StyledTitle>
      );
    } else {
      return creditCard.map((card, index) => (
        <CreditCard
          key={`${card.cardNumber}-${index}`}
          cardNumber={card.cardNumber}
          addedDate={card.created}
          expiryMonth={card.expiryMonth}
          expiryYear={card.expiryYear}
          blackListed={card.blacklisted}
        />
      ));
    }
  };

  return (
    <Accordion sx={{ mt: 2 }} defaultExpanded component={Paper} elevation={3}>
      <AccordionSummary
        expandIcon={<KeyboardArrowDown />}
        aria-controls="credit-card-details-content"
        id="credit-card-details-header"
      >
        <StyledTitle sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1.2rem' }}>
          {t('creditCard.title')}
        </StyledTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {generateCards()}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CreditCardList;
