import i18n from '../../i18n';
import { t } from 'i18next';

export const formatDate = (orderTime: string | Date) => {
  return orderTime
    ? new Date(orderTime)
        .toLocaleDateString(i18n.language, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
        .replace('.', '')
    : t('generic.notAvailable');
};
