import React, { useEffect } from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { TabIds } from '@model/LoyaltyTabsConfig';
import { RequestStatus } from '@model/requestStatus';
import { Signal } from '@preact/signals';

interface Props extends React.PropsWithChildren {
  tab: (typeof TabIds)[keyof typeof TabIds];
  prerequisiteRequests?: Signal<RequestStatus>[];
}

const LoyaltyTab: React.FC<Props> = ({
  tab,
  prerequisiteRequests,
  children,
}) => {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (tab: string) => void;
  }>();

  const location = useLocation();
  const navigate = useNavigate();

  // navigate back to root tab if any of the prerequisite requests are in error
  if (prerequisiteRequests?.some((request) => request.value.isError)) {
    navigate(`/loyalty${location.search}`);
  }

  const matchesTab = useMatch(`/loyalty/${tab}/*`);

  useEffect(() => {
    if (matchesTab) {
      setActiveTab(tab);
    }
  }, [matchesTab]);

  return children;
};

export default LoyaltyTab;
