import React, { useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell } from '../../../mui-helpers/customComponentStyles';
import { IOrderDetailsResult } from '@model/order';
import ProductTableRows from './ProductTableRows';
import { TextConstants } from '@utils/TextConstant';

interface ProductTableProps {
  products: IOrderDetailsResult['products'];
}

const ProductTable: React.FC<ProductTableProps> = ({ products }) => {
  const [selectedProductId, setSelectedProductId] = useState<
    number | string | null
  >(null);

  const handleRowClick = (productId: number | string) => {
    setSelectedProductId(selectedProductId === productId ? null : productId);
  };

  const PRODUCT_HEADERS = TextConstants.PRODUCT_HEADERS;

  return (
    <TableContainer component={Paper} elevation={3}>
      <Table sx={{ minWidth: 650 }} aria-label="order details">
        <TableHead>
          <TableRow sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
            <StyledTableCell>{PRODUCT_HEADERS.ARTICLE_ID}</StyledTableCell>
            <StyledTableCell>{PRODUCT_HEADERS.PRODUCT_NAME}</StyledTableCell>
            <StyledTableCell>{PRODUCT_HEADERS.PRICE_DKK}</StyledTableCell>
            <StyledTableCell>{PRODUCT_HEADERS.QUANTITY}</StyledTableCell>
            <StyledTableCell>{PRODUCT_HEADERS.ORDER_STATUS}</StyledTableCell>
            <StyledTableCell>{PRODUCT_HEADERS.TOTAL_DISCOUNT}</StyledTableCell>
            <StyledTableCell>
              {PRODUCT_HEADERS.EXPECTED_DELIVERY_DATE}
            </StyledTableCell>
            <StyledTableCell>{PRODUCT_HEADERS.SHIPMENT_NUMBER}</StyledTableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <ProductTableRows
            products={products}
            selectedProductId={selectedProductId}
            onRowClick={handleRowClick}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
