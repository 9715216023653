import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { currencyFormatter } from '@utils/priceFormatter';
import { formatDate } from '@utils/date-utils/formatDate';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IOrderResult } from '@model/order';
import DataGridWrapper from '../../mui-helpers/DataGridWrapper';
import appState from '@/state';

const OrderTable = () => {
  const navigate = useNavigate();
  const customerBrand = appState.customer.brand.value;
  const customerOrders = appState.customer.orders.value;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'orderId',
        headerName: 'Ordre',
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="contained"
            onClick={() => {
              navigate(`brands/${customerBrand}/orders/${params.value}`);
            }}
          >
            {params.value}
          </Button>
        ),
      },
      {
        field: 'price',
        headerName: 'Pris',
        flex: 1,
        sortable: true,
        valueFormatter: (params) => currencyFormatter(params, 'DKK'),
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
      },
      {
        field: 'phone',
        headerName: 'Mobile',
        flex: 1,
      },
      {
        field: 'orderTime',
        headerName: 'Ordre dato',
        flex: 1,
        sortable: true,
        sortComparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
        valueFormatter: (params) => formatDate(params),
      },
    ],
    [customerBrand, navigate],
  );

  const rows = useMemo(
    () =>
      customerOrders?.map((order: IOrderResult) => ({
        ...order,
        id: order.orderId,
      })) || [],
    [customerOrders],
  );

  return (
    <DataGridWrapper
      rows={rows}
      columns={columns}
      initialSortField="orderTime"
      initialSortOrder="desc"
      pageSize={10}
      pageSizeOptions={[10, 20, 30]}
    />
  );
};

export default OrderTable;
