import { UseQueryResult } from '@tanstack/react-query';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import RetryButton from '@components/mui-helpers/RetryButton';
import SnackbarCloseButton from '@components/SnackbarCloseButton';
import React, { useEffect } from 'react';
import { CustomError } from '@/service/CustomError';

const useFailedRequestSnackbar = (
  errorMessage: string,
  ...requests: UseQueryResult[]
) => {
  const aggregateRequestStatus = requests
    .map((r) => r.isFetching || r.isLoading)
    .reduce((acc, value) => acc || value, false);

  useEffect(() => {
    const failedRequests = requests.filter(
      (r) =>
        ((r.isError || r.isRefetchError) &&
          !CustomError.isCustomErrorPayload(r.error)) ||
        (CustomError.isCustomErrorPayload(r.error) &&
          r.error.errorCode !== 404),
    ).length;

    if (failedRequests > 0) {
      enqueueSnackbar(errorMessage, {
        action: (snackbarKey) => (
          <Box>
            <RetryButton
              onClick={() => {
                closeSnackbar(snackbarKey);
                requests
                  .filter((r) => r.isError || r.isRefetchError)
                  .forEach((request) => {
                    request.refetch();
                  });
              }}
            />
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          </Box>
        ),
        autoHideDuration: null,
        preventDuplicate: true,
      });
    }
  }, [aggregateRequestStatus]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useFailedRequestSnackbar;
