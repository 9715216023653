interface GenericBrandProperties<
  BrandKey,
  BrandName,
  CustomerProgramKey extends string | undefined,
> {
  key: BrandKey;
  label: BrandName;
  logo: string;

  loyalty: {
    customerProgram: {
      signupDateKey: `${CustomerProgramKey}SignupDate`;
      membershipNrKey: `${CustomerProgramKey}MembershipNr`;
    };
    areMemberOffersBurnable: boolean;
    isWalletAvailable: boolean;
    isProductDataAvailable: boolean;
  };

  orders: boolean;
}

interface GenericBrandPropertiesWithoutCustomerProgram<BrandKey, BrandName>
  extends Omit<
    GenericBrandProperties<BrandKey, BrandName, undefined>,
    'loyalty'
  > {
  loyalty: false;
}

export type BrandProperties =
  | GenericBrandProperties<'bilka', 'Bilka', 'cpblk'>
  | GenericBrandPropertiesWithoutCustomerProgram<'br', 'BR'>
  | GenericBrandProperties<'carlsjr', "Carl's Jr.", 'cpcjr'>
  | GenericBrandProperties<'foetex', 'Føtex', 'cpfot'>
  | GenericBrandProperties<'nettode', 'Netto DE', 'cpnde'>
  | GenericBrandProperties<'nettodk', 'Netto DK', 'cpndk'>
  | GenericBrandPropertiesWithoutCustomerProgram<'nettopl', 'Netto PL'>
  | GenericBrandProperties<'salling', 'Salling', 'cpsal'>;

type InferGenericBrandPropertiesType<T> =
  T extends GenericBrandProperties<
    infer BrandKey,
    infer BrandName,
    infer CustomerProgramKey
  >
    ? CustomerProgramKey extends undefined
      ? never
      : {
          BrandKey: BrandKey;
          BrandName: BrandName;
          CustomerProgramKey: CustomerProgramKey;
        }
    : never;

type InferBrandProperties = InferGenericBrandPropertiesType<BrandProperties>;

export type BrandKey = BrandProperties['key'];
export type BrandName = BrandProperties['label'];
export type CustomerProgramKey = InferBrandProperties['CustomerProgramKey'];
export type CustomerProgramMembershipNr = `${CustomerProgramKey}MembershipNr`;
export type CustomerProgramSignupDate = `${CustomerProgramKey}SignupDate`;

export class Brand {
  constructor(private readonly brandProperties: BrandProperties) {}

  get key() {
    return this.brandProperties.key;
  }

  get label() {
    return this.brandProperties.label;
  }

  get logo() {
    return this.brandProperties.logo;
  }

  get customerProgramSignupDate() {
    if (this.brandProperties.loyalty) {
      return this.brandProperties.loyalty.customerProgram.signupDateKey;
    } else {
      throw new Error('customer program values not supported for this brand');
    }
  }

  get customerProgramMembershipNr() {
    if (this.brandProperties.loyalty) {
      return this.brandProperties.loyalty.customerProgram.membershipNrKey;
    } else {
      throw new Error('customer program values not supported for this brand');
    }
  }

  get isSupportedForLoyalty() {
    return (
      'loyalty' in this.brandProperties && Boolean(this.brandProperties.loyalty)
    );
  }

  get isSupportedForOrders() {
    return this.brandProperties.orders ?? false;
  }

  get areMemberOffersBurnable() {
    if (this.brandProperties.loyalty) {
      return this.brandProperties.loyalty.areMemberOffersBurnable;
    }
    return false;
  }

  get isWalletAvailable() {
    if (this.brandProperties.loyalty) {
      return this.brandProperties.loyalty.isWalletAvailable;
    }
    return false;
  }

  get isProductDataAvailable() {
    if (this.brandProperties.loyalty) {
      return this.brandProperties.loyalty.isProductDataAvailable;
    }
    return false;
  }
}
