import React from 'react';
import { TextField, Checkbox, Button, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import { IStore } from '@model/store';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  autocomplete: {
    width: '100%',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface StoreAutocompleteProps {
  stores: IStore[];
  selectedStores: IStore[];
  setSelectedStores: (stores: IStore[]) => void;
}

const StoreAutocomplete: React.FC<StoreAutocompleteProps> = ({
  stores,
  selectedStores,
  setSelectedStores,
}) => {
  const classes = useStyles();

  const checkOption = (option: IStore) => {
    return selectedStores.some((store) => store.sapSiteId === option.sapSiteId);
  };

  const checkGroup = (group: string) => {
    const groupLength = stores.filter((store) => store.brand === group).length;
    const selectedGroupLength = selectedStores.filter(
      (store) => store.brand === group,
    ).length;
    return groupLength === selectedGroupLength;
  };

  const selectGroup = (group: string) => {
    const groupedStores = stores.filter((store) => store.brand === group);
    const selectedGroupStores = selectedStores.filter(
      (store) => store.brand === group,
    );

    if (selectedGroupStores.length > 0) {
      setSelectedStores(
        selectedStores.filter((store) => store.brand !== group),
      );
    } else {
      setSelectedStores([...selectedStores, ...groupedStores]);
    }
  };

  const unselectOption = (sapSiteId: string) => {
    setSelectedStores(
      selectedStores.filter((store) => store.sapSiteId !== sapSiteId),
    );
  };

  return (
    <Autocomplete
      className={classes.autocomplete}
      id="store-select-demo"
      options={stores}
      classes={{
        option: classes.option,
      }}
      onChange={(_, option) => setSelectedStores([...(option as IStore[])])}
      value={selectedStores}
      inputValue={''}
      autoHighlight
      multiple
      disableCloseOnSelect
      isOptionEqualToValue={(option: IStore, value: IStore) =>
        option.sapSiteId === value.sapSiteId
      }
      getOptionLabel={(option: IStore) => option.name}
      groupBy={(option: IStore) => option.brand}
      renderOption={(props, option: IStore) => (
        <li {...props}>
          <Checkbox
            key={option.sapSiteId}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={checkOption(option)}
          />
          {option.name}
        </li>
      )}
      renderGroup={(params) => (
        <>
          <Checkbox
            key={params.group}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={checkGroup(params.group)}
            onChange={() => selectGroup(params.group)}
          />
          <span>{params.group}</span>
          {params.children}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a store"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
      renderTags={() => {
        return selectedStores.map((tag) => (
          <Button
            size="small"
            variant="contained"
            key={tag.sapSiteId}
            endIcon={<CancelIcon />}
            onClick={() => unselectOption(tag.sapSiteId)}
          >
            {tag.name}
          </Button>
        ));
      }}
    />
  );
};

export default StoreAutocomplete;
