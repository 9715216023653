import React, { useEffect, useMemo, useState } from 'react';
import { TextField, Typography, Button } from '@mui/material';
import { IShopRequestParams, IStore } from '@model/store';
import { stockColumns } from '../../order-details-view/table-columns/stockColumns';
import { IStock, StockLevels } from '@model/stock';
import StockService from '../../../service/StockService';
import { enqueueSnackbar } from 'notistack';
import { requestValidation } from '@utils/requestValidation';
import DataGridWrapper from '../../mui-helpers/DataGridWrapper';
import SearchIcon from '@mui/icons-material/Search';
import StoreAutocomplete from './StoreAutocomplete';
import { AppSection, getSupportedBrands } from '@utils/useAppSection';

const StocksLayout = () => {
  const [foetexStores, setFoetexStores] = useState<IStore[]>([]);
  const [bilkaStores, setBilkaStores] = useState<IStore[]>([]);
  const [brStores, setBrStores] = useState<IStore[]>([]);
  const [onlineWarehouses, setOnlineWarehouses] = useState<IStore[]>([]);
  const [siteIds, setSiteIds] = useState<string[]>([]);
  const [stocks, setStocks] = useState<StockLevels[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedStores, setSelectedStores] = useState<IStore[]>([]);
  const storesPerPage = 200;

  useEffect(() => {
    getSupportedBrands(AppSection.OrderSearch).forEach((brand) => {
      performShopSearch({ brand: brand.key, storesPerPage });
    });
    setOnlineWarehouses([
      { brand: 'online', sapSiteId: '9002', name: 'Årslev' },
      { brand: 'online', sapSiteId: '9010', name: 'Skejby' },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const ids = selectedStores.map((store) => store.sapSiteId);
    setSiteIds(ids);
  }, [selectedStores]);

  const stockService = new StockService();

  const performShopSearch = async (params: IShopRequestParams) => {
    const response = await stockService.getStoresByBrand({
      brand: params.brand,
      storesPerPage: params.storesPerPage,
    });
    if (params.brand === 'foetex') {
      setFoetexStores(response);
    } else if (params.brand === 'bilka') {
      setBilkaStores(response);
    } else if (params.brand === 'br') {
      setBrStores(response);
    }
  };

  const settingStocks = (stockResponse: IStock) => {
    const mappedBrands: Record<string, Map<string, string>> = {
      '16': bilkaMap,
      '20': brMap,
      '90': onlineWarehouseMap,
      default: foetexMap,
    };

    const stockLevels: StockLevels[] = stockResponse.stockLevels
      .filter((stockLevel) => stockLevel.currentStock !== 0)
      .map((stockLevel) => {
        const mappedBrand =
          mappedBrands[stockLevel.siteId.slice(0, 2)] || mappedBrands.default;
        stockLevel.siteId += ' - ' + mappedBrand?.get(stockLevel.siteId);
        return stockLevel;
      });

    setStocks(stockLevels);
  };

  const returnStoreMap = (stores: IStore[]) => {
    return new Map(
      stores?.map((object) => {
        return [object.sapSiteId, object.name];
      }),
    );
  };

  const foetexMap = returnStoreMap(foetexStores || []);
  const bilkaMap = returnStoreMap(bilkaStores || []);
  const brMap = returnStoreMap(brStores || []);
  const onlineWarehouseMap = returnStoreMap(onlineWarehouses || []);

  const onSearch = async (value: string) => {
    try {
      requestValidation(value, siteIds);
      const response = await stockService.getStocks({
        articleId: value,
        siteId: siteIds,
      });
      settingStocks(response);
    } catch (error: any) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        enqueueSnackbar(
          error?.message
            ? error.message + ' for article id: ' + value
            : 'Aktier ikke fundet',
          { variant: 'warning', autoHideDuration: 4000 },
        );
      }
      setStocks([]);
    }
  };

  const rows = useMemo(
    () =>
      stocks?.map((transaction) => ({
        ...transaction,
        id: transaction,
      })) || [],
    [stocks],
  );

  const stores = [
    ...foetexStores,
    ...bilkaStores,
    ...brStores,
    ...onlineWarehouses,
  ];

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Stock levels
      </Typography>
      <StoreAutocomplete
        stores={stores}
        selectedStores={selectedStores}
        setSelectedStores={setSelectedStores}
      />
      <Typography variant="h6" gutterBottom>
        Artikelnr
      </Typography>
      <TextField
        placeholder="Indsæt artikelnummer her"
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <Button
              onClick={() => onSearch(searchTerm)}
              startIcon={<SearchIcon />}
            >
              Søg
            </Button>
          ),
        }}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch(searchTerm);
          }
        }}
      />
      <DataGridWrapper
        rows={rows}
        columns={stockColumns}
        initialSortField="siteId"
        initialSortOrder="asc"
        pageSize={6}
        pageSizeOptions={[6, 12, 24]}
      />
    </div>
  );
};

export default StocksLayout;
