import { GridColDef } from '@mui/x-data-grid';

export const stockColumns: GridColDef[] = [
  {
    field: 'siteId',
    headerName: 'Butik No.',
    width: 150,
    sortable: true,
  },
  {
    field: 'currentStock',
    headerName: 'Nuværende Lager',
    width: 150,
    sortable: true,
  },
];
