import { computed, effect, signal } from '@preact/signals';
import { ICustomerDetailsByBrand } from '@model/customer';
import { ICreditCardDetail } from '@components/loyalty/cards/ICreditCardDetail';
import { IWalletResult, IWalletTransactionResult } from '@model/wallet';
import { OffersData } from '@model/offer';
import { IPunchCardResponse } from '@model/punchcard';
import { IReceiptSummaryResult } from '@model/receipt';
import { default as customerState } from '@/state/customer/customer';
import { DEFAULT_REQUEST_STATUS, RequestStatus } from '@model/requestStatus';

const loyaltyState = {
  customer: {
    data: signal<ICustomerDetailsByBrand | undefined>(undefined),
    request: signal<RequestStatus>(DEFAULT_REQUEST_STATUS),
  },
  creditCard: {
    data: signal<ICreditCardDetail[] | undefined>(undefined),
    request: signal<RequestStatus>(DEFAULT_REQUEST_STATUS),
  },
  useWallet: signal(false),
  wallet: {
    data: signal<IWalletResult | undefined>(undefined),
    request: signal<RequestStatus>(DEFAULT_REQUEST_STATUS),
  },
  walletTransactions: {
    data: signal<IWalletTransactionResult | undefined>(undefined),
    request: signal<RequestStatus>(DEFAULT_REQUEST_STATUS),
  },
  memberOffers: {
    data: signal<OffersData | undefined>(undefined),
    request: signal<RequestStatus>(DEFAULT_REQUEST_STATUS),
  },
  punchCards: {
    data: signal<IPunchCardResponse | undefined>(undefined),
    request: signal<RequestStatus>(DEFAULT_REQUEST_STATUS),
  },
  receipts: {
    data: signal<IReceiptSummaryResult[]>([]),
    request: signal<RequestStatus>(DEFAULT_REQUEST_STATUS),
  },
  isFetchingMemberData: computed(
    (): boolean =>
      loyaltyState.customer.request.value.isFetching ||
      loyaltyState.creditCard.request.value.isFetching ||
      loyaltyState.memberOffers.request.value.isFetching ||
      loyaltyState.punchCards.request.value.isFetching ||
      loyaltyState.receipts.request.value.isFetching ||
      loyaltyState.wallet.request.value.isFetching ||
      loyaltyState.walletTransactions.request.value.isFetching,
  ),
};

effect(() => {
  loyaltyState.useWallet.value = customerState.brand.value !== 'salling';
});

// Reset loyalty data when customer changes
effect(() => {
  if (loyaltyState.customer.data.value) {
    loyaltyState.creditCard.data.value = undefined;
    loyaltyState.wallet.data.value = undefined;
    loyaltyState.walletTransactions.data.value = undefined;
    loyaltyState.memberOffers.data.value = undefined;
    loyaltyState.punchCards.data.value = undefined;
    loyaltyState.receipts.data.value = [];
  }
});

export default loyaltyState;
