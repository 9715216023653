import React, { forwardRef } from 'react';
import ListItem from '@mui/material/ListItem';
import { StyledDescription } from './customComponentStyles';

interface DetailItemProps {
  label: string;
  value: React.ReactNode;
  styles?: React.CSSProperties;
  onClick?: () => void;
}

const DetailItem = forwardRef<HTMLLIElement, DetailItemProps>(
  ({ label, value, styles, onClick, ...props }, ref) => (
    <ListItem
      disablePadding
      onClick={onClick}
      sx={{ ...styles }}
      ref={ref} // this is needed to make tooltip work without error
      {...props} //this ensures all other props are passed down to the ListItem including the ones from tooltip
    >
      <StyledDescription>
        <strong>{label}:</strong> {value}
      </StyledDescription>
    </ListItem>
  ),
);

export default DetailItem;
