import React, { ElementType } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { TypographyProps } from '@mui/material';

interface ListItemWithIconProps {
  icon: React.ReactNode;
  primary?: string | number;
  secondary?: string | number;
  secondaryTypographyProps?: TypographyProps<ElementType>;
  primaryTypographyProps?: TypographyProps<ElementType>;
  onClick?: () => void;
}
const ListItemWithIcon: React.FC<ListItemWithIconProps> = ({
  icon,
  primary,
  secondary,
  secondaryTypographyProps = {
    variant: 'body1',
    style: { fontWeight: 'bold', color: '#424242' },
  },
  primaryTypographyProps = {
    variant: 'caption',
    style: { color: 'darkgray' },
  },
  onClick,
}) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      {secondaryTypographyProps && (
        <ListItemText
          primary={primary}
          secondary={secondary}
          secondaryTypographyProps={secondaryTypographyProps}
          primaryTypographyProps={primaryTypographyProps}
        />
      )}
    </ListItem>
  );
};

export default ListItemWithIcon;
