export const TabIds = {
  CUSTOMER_PROFILE: 'customer' as 'customer',
  WALLET: 'wallet' as 'wallet',
  MEMBER_OFFERS: 'offers' as 'offers',
  PUNCH_CARDS: 'punchcards' as 'punchcards',
  RECEIPTS: 'receipts' as 'receipts',
};

export const TabNames = {
  CUSTOMER_PROFILE: 'loyaltyTabs.customerProfile',
  WALLET: 'loyaltyTabs.wallet',
  MEMBER_OFFERS: 'loyaltyTabs.memberOffers',
  PUNCH_CARDS: 'loyaltyTabs.punchCards',
  RECEIPTS: 'loyaltyTabs.receipts',
};
