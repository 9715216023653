import React from 'react';
import Box from '@mui/material/Box';
import { IProduct } from '@model/punchcard';
import { emptyValueReplacement } from '@utils/emptyValueReplacement';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import {
  EllipsisStyledTitle,
  StyledBorderCard,
  StyledDescription,
} from '../../mui-helpers/customComponentStyles';
import { currencyFormatter } from '@utils/priceFormatter';
import { useTranslation } from 'react-i18next';
import DetailItem from '../../mui-helpers/DetailItem';
import CustomImage from '../../mui-helpers/CustomImage';
import InfoIconButton from '../../mui-helpers/InfoIconButton';
import { copyToClipboard } from '@utils/copyToClipboard';

interface ProductDetailsProps {
  products?: IProduct[];
}

const PunchCardProductDetails: React.FC<ProductDetailsProps> = ({
  products,
}) => {
  const { t } = useTranslation();

  if (!products || products.length === 0) return null;

  return (
    <Box>
      <Box>
        <Grid
          container
          sx={{
            gap: 2,
            textAlign: 'center',
            padding: 1,
          }}
        >
          {products.map((product, index) => {
            const {
              imageId,
              title,
              description,
              subtitle,
              id,
              unitPriceText,
              currency,
              discountPrice,
              originalPrice,
              discountAmount,
              discountPercentage,
            } = product;

            return (
              <Grid item xs={12} md={4} lg={3} key={index}>
                <StyledBorderCard
                  elevation={0}
                  sx={{ height: '100%', position: 'relative' }}
                >
                  <InfoIconButton
                    description={emptyValueReplacement(description) as string}
                  />
                  <CustomImage imageId={imageId} title={title} />
                  <CardContent>
                    <Box
                      sx={{
                        textAlign: 'center',
                        color: 'text.secondary',
                        marginBottom: '1rem',
                      }}
                    >
                      <Tooltip title={title}>
                        <EllipsisStyledTitle
                          sx={{
                            fontSize: '0.9rem',
                          }}
                        >
                          {emptyValueReplacement(title)}
                        </EllipsisStyledTitle>
                      </Tooltip>
                      <StyledDescription sx={{ fontSize: '0.7rem' }}>
                        {emptyValueReplacement(subtitle)}
                      </StyledDescription>
                    </Box>
                    <List>
                      <Tooltip
                        title={
                          id
                            ? t('productHeaders.copyArticleId')
                            : t('productHeaders.noArticleId')
                        }
                      >
                        <DetailItem
                          label={t('productHeaders.articleId')}
                          value={emptyValueReplacement(id)}
                          styles={{ cursor: 'pointer' }}
                          onClick={() => {
                            id && copyToClipboard(id);
                          }}
                        />
                      </Tooltip>
                      <DetailItem
                        label={t('punchCard.unitPrice')}
                        value={emptyValueReplacement(unitPriceText)}
                      />
                      {currency && (
                        <>
                          {discountPrice !== undefined && (
                            <DetailItem
                              label={t('punchCard.discountPrice')}
                              value={currencyFormatter(discountPrice, currency)}
                            />
                          )}
                          {originalPrice !== undefined && (
                            <DetailItem
                              label={t('punchCard.originalPrice')}
                              value={currencyFormatter(originalPrice, currency)}
                            />
                          )}
                        </>
                      )}
                      <DetailItem
                        label={t('punchCard.discountAmount')}
                        value={emptyValueReplacement(discountAmount)}
                      />
                      <DetailItem
                        label={t('punchCard.discountPercentage')}
                        value={emptyValueReplacement(discountPercentage)}
                      />
                    </List>
                  </CardContent>
                </StyledBorderCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default PunchCardProductDetails;
