import React, { useCallback, useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from 'react-router-dom';
import UserContextMenu from '@contexts/UserContextMenu';
import SearchBar from '../order-details-view/SearchBar';
import { useTranslation } from 'react-i18next';
import { ICustomerDetailsByBrand } from '@model/customer';
import { SearchType } from '@utils/checkInputType';
import {
  fetchCustomerDetails,
  fetchCustomerDetailsWithBrand,
  fetchLoyaltyProfileByGigyaId,
  fetchLoyaltyProfileByMembershipId,
} from '@/service/CustomerService';
import { AxiosError } from 'axios';
import { orderFetcherUtil } from '@utils/orderFetcherUtil';
import { useFirestoreTicketListener } from '@hooks/useFirestoreTicketListener';
import {
  StyledAppBar,
  StyledLogoBox,
  StyledTypography,
} from './shared/styledNavBar';
import { useSettingsContext } from '@contexts/SettingsContext';
import { loyaltyRoute, orderSearchRoute } from '@/AppRouter';
import useAppSection, { AppSection } from '@utils/useAppSection';
import { enqueueSnackbar } from 'notistack';
import AppVersion from '@components/AppVersion';
import { BrandKey } from '@model/brand';
import appState from '@/state';
import { batch } from '@preact/signals';
import { handleLoyaltyResponses } from '@utils/handleLoyaltyResponses';

interface UserAppBarProps {
  isAuthorized: boolean | null;
  isLoyaltyAuthorized: boolean | null;
}

const UserAppBar: React.FC<UserAppBarProps> = ({
  isAuthorized,
  isLoyaltyAuthorized,
}) => {
  const navigate = useNavigate();
  const customerBrand = appState.customer.brand.value;
  const { t } = useTranslation();
  const { settings } = useSettingsContext();

  const [manualInput, setManualInput] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [selectedBrand, setSelectedBrand] = useState<BrandKey>(settings.brand);

  // Set the selected brand to the customer's brand if it exists. This is used when we perform the search from ProgramMemberships.tsx
  useEffect(() => {
    if (customerBrand) {
      setSelectedBrand(customerBrand);
    }
  }, [customerBrand]);

  const handle360LogoClick = () => {
    navigate(orderSearchRoute);
  };

  const handleLoyLogoClick = () => {
    navigate(loyaltyRoute);
  };

  const { appSection, isAppSection } = useAppSection();

  const handleFetchCustomerDetails = useCallback(
    async (inputQuery: string, brand: BrandKey, searchType: SearchType) => {
      if (!inputQuery && !brand) {
        enqueueSnackbar(t('errors.selectBrandOrEmail'));
        return;
      }

      try {
        if (isAppSection(AppSection.OrderSearch)) {
          await orderSearch(inputQuery, brand);
        } else if (isAppSection(AppSection.Loyalty)) {
          switch (searchType) {
            case SearchType.EMAIL:
              await getLoyaltyDetailsByEmail(inputQuery, brand);
              break;
            case SearchType.MEMBER_ID:
              await getLoyaltyDetailsByMembershipId(inputQuery);
              break;
            case SearchType.GIGYA_ID:
              await getLoyaltyDetailsByGigyaId(inputQuery, brand);
              break;
            default:
              enqueueSnackbar(t('errors.selectSearchType'));
          }
        }
      } catch (error) {
        console.error('CustomerFetchDetails', error);
        const message = isAppSection(AppSection.Loyalty)
          ? t('errors.customerNotInLoyaltyProgram', { brand })
          : t('errors.customerDetailsFetch');
        enqueueSnackbar(message);
        setErrorMessage(handleFailure(error));
        appState.customer.reset();
      }
    },
    [appSection], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const orderSearch = async (inputQuery: string, brand: BrandKey) => {
    try {
      const customerOrders = await orderFetcherUtil(inputQuery, brand);
      if (!customerOrders) return handleFetchError();

      const customerDetails = await fetchCustomerDetails(
        customerOrders[0].email,
      );
      if (!customerDetails) return handleFetchError();

      batch(() => {
        appState.customer.brand.value = brand;
        appState.customer.details.value = customerDetails;
        appState.customer.orders.value = customerOrders;
      });
    } catch (error) {
      console.error('CustomerFetchDetails', error);
      enqueueSnackbar(t('errors.customerDetailsFetch'));
    }
  };

  const getLoyaltyDetailsByEmail = async (
    inputQuery: string,
    brand: BrandKey,
  ) => {
    try {
      const loyaltyResponse = await fetchCustomerDetailsWithBrand(
        inputQuery,
        brand,
      );
      loyaltyResponse
        ? updateStates(loyaltyResponse)
        : handleNoLoyaltyResponse(brand);
    } catch (error) {
      handleLoyaltyResponses(error as AxiosError, enqueueSnackbar, t);
    }
  };

  const getLoyaltyDetailsByMembershipId = async (inputQuery: string) => {
    const loyaltyResponse = await fetchLoyaltyProfileByMembershipId(inputQuery);
    if (loyaltyResponse) {
      updateStates(loyaltyResponse);
    } else {
      enqueueSnackbar(t('errors.failedToFetchByMembershipId'));
      appState.customer.reset();
    }
  };

  const getLoyaltyDetailsByGigyaId = async (
    inputQuery: string,
    brand: BrandKey,
  ) => {
    const loyaltyResponse = await fetchLoyaltyProfileByGigyaId(
      inputQuery,
      brand,
    );
    if (loyaltyResponse) {
      updateStates(loyaltyResponse);
    } else {
      enqueueSnackbar(t('errors.failedToFetchByGigyaId', { brand }));
      appState.customer.reset();
    }
  };

  const updateStates = (loyaltyResponse: ICustomerDetailsByBrand) => {
    setSelectedBrand(loyaltyResponse.brand as BrandKey);
    batch(() => {
      appState.customer.brand.value = loyaltyResponse.brand as BrandKey;
      appState.customer.details.value = loyaltyResponse.user;
      appState.customer.profile.value = loyaltyResponse.profile;
    });
  };

  const handleFetchError = () => {
    enqueueSnackbar(t('errors.customerDetailsFetch'));
    appState.customer.reset();
  };

  const handleNoLoyaltyResponse = (brand: BrandKey) => {
    enqueueSnackbar(t('errors.customerNotInLoyaltyProgram', { brand }));
    appState.customer.reset();
  };

  const handleFailure = (error: any) => {
    console.error('Error fetching customer details:', error);
    return error instanceof AxiosError
      ? error.response?.data?.message || error.message
      : error.message;
  };

  const showFirestoreDialog = useFirestoreTicketListener(
    handleFetchCustomerDetails,
  );

  return (
    <StyledAppBar position="static">
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
          >
            {isAuthorized && (
              <StyledLogoBox onClick={handle360LogoClick}>
                <StyledTypography>Zendesk36</StyledTypography>
                <PublicIcon sx={{ fontSize: '1.125rem' }} />
              </StyledLogoBox>
            )}
            {isLoyaltyAuthorized && (
              <StyledLogoBox onClick={handleLoyLogoClick} sx={{ ml: 2 }}>
                <StyledTypography>Loyalty</StyledTypography>
              </StyledLogoBox>
            )}
          </Box>
          <SearchBar
            manualInput={manualInput}
            setManualInput={setManualInput}
            handleFetchCustomerDetails={handleFetchCustomerDetails}
            errorMessage={errorMessage}
            selectedBrand={selectedBrand}
            setSelectedBrand={(brand: BrandKey) => setSelectedBrand(brand)}
          />
          <Box display="flex" alignItems="center" height="100%">
            <UserContextMenu />
            <AppVersion />
          </Box>
        </Box>
      </Toolbar>
      {showFirestoreDialog()}
    </StyledAppBar>
  );
};

export default UserAppBar;
