export interface RequestStatus {
  isFetching: boolean;
  /**
   * Boolean indicating whether a request is completed,
   * regardless whether with success or with an error.
   */
  isFetched: boolean;
  isError: boolean;
}

export const DEFAULT_REQUEST_STATUS = {
  isFetching: false,
  isFetched: false,
  isError: false,
} as const;
