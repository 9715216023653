import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {
  HoverListItem,
  StyledBorderCard,
  StyledTitle,
} from '../../mui-helpers/customComponentStyles';
import { containsSearchTerm } from '@utils/containsSearchTerm';
import { IReceiptSummaryResult, ReceiptType } from '@model/receipt';
import { useTranslation } from 'react-i18next';
import { getLocalizedReceiptType } from '@utils/receiptUtil';
import { currencyFormatter } from '@utils/priceFormatter';
import Loader from '../../mui-helpers/Loader';
import DetailItem from '../../mui-helpers/DetailItem';
import { fetchReceipts } from '@/service/ReceiptService';
import { trackPromise } from 'react-promise-tracker';
import { receiptChipColor } from '@utils/chipColor';
import { formatDateWithTimestamp } from '@utils/date-utils/formatDateWithTimestamp';
import { enqueueSnackbar } from 'notistack';
import appState from '@/state';

interface IReceiptListProps {
  receipts: IReceiptSummaryResult[];
  onSelectReceipt: (receiptId: string) => void;
}

const ReceiptList: React.FC<IReceiptListProps> = ({
  receipts,
  onSelectReceipt,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredReceipts, setFilteredReceipts] = useState(receipts);
  const [page, setPage] = useState(1);
  const [selectedReceiptId, setSelectedReceiptId] = useState(receipts[0]?.id);
  const customerBrand = appState.customer.brand.value;
  const customerDetails = appState.customer.details.value;
  const { t } = useTranslation();

  useEffect(() => {
    if (receipts.length > 0) {
      setSelectedReceiptId(receipts[0].id);
    }
    setFilteredReceipts(receipts);
  }, [receipts]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    setFilteredReceipts(
      receipts.filter((receipt) => containsSearchTerm(receipt, query)),
    );
  };

  const getMoreReceipts = async () => {
    if (!customerDetails || !customerBrand) {
      enqueueSnackbar(t('messages.searchCustomerFirst'));
      return;
    }
    try {
      const newReceipts = await trackPromise(
        fetchReceipts(customerDetails.uid, customerBrand, 100, page * 100),
        'receipt-list-area',
      );
      setFilteredReceipts([...filteredReceipts, ...newReceipts]);
      setPage(page + 1);
    } catch (error) {
      console.error('Failed to load more receipts:', error);
      enqueueSnackbar(t('receipts.noMoreReceipts'));
    }
  };

  const tooltipText: Record<ReceiptType, string> = {
    LIGHT: t('receipt.lightTooltip'),
    FULL: t('receipt.fullTooltip'),
    MERGED: t('receipt.mergedTooltip'),
  };

  const renderReceiptTypeChip = (type: ReceiptType) => {
    const localizedType = getLocalizedReceiptType(type, t);

    return (
      <Tooltip title={tooltipText[type]}>
        <Chip
          label={localizedType}
          sx={{
            ...receiptChipColor[type],
          }}
        />
      </Tooltip>
    );
  };

  const handleSelectReceipt = (receiptId: string) => {
    setSelectedReceiptId(receiptId);
    onSelectReceipt(receiptId);
  };

  return (
    <Box>
      <Loader area="receipt-list-area" />
      <TextField
        label={t('receipt.search')}
        variant="outlined"
        sx={{ marginBottom: '1rem' }}
        size="small"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <StyledBorderCard
        sx={{
          overflowY: 'auto',
          minHeight: {
            xs: '50vh',
            sm: '70vh',
            md: '100vh',
          },
        }}
      >
        <List sx={{ marginTop: 0, paddingTop: 0 }}>
          {filteredReceipts.map((receipt) => (
            <HoverListItem
              key={receipt.id}
              onClick={() => handleSelectReceipt(receipt.id)}
              selected={selectedReceiptId === receipt.id}
            >
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={10}>
                  <Grid item xs={12}>
                    <StyledTitle>
                      {`${receipt.storeName} - ${currencyFormatter(
                        receipt.amount,
                        receipt.currency,
                      )}`}
                    </StyledTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <DetailItem
                      label={t('generic.dateLabel')}
                      value={formatDateWithTimestamp(receipt.date)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <DetailItem
                        label={t('receipt.discountLabel')}
                        value={currencyFormatter(
                          receipt.memberDiscount,
                          receipt.currency,
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {renderReceiptTypeChip(receipt.type)}
                </Grid>
              </Grid>
            </HoverListItem>
          ))}
        </List>
        <Button onClick={getMoreReceipts} sx={{ marginTop: '1rem' }}>
          {t('receipt.loadMore')}
        </Button>
      </StyledBorderCard>
    </Box>
  );
};

export default ReceiptList;
