import { useMatch } from 'react-router-dom';
import brandConfig from '@/brandConfig';
import { Brand, BrandKey } from '@model/brand';

export enum AppSection {
  OrderSearch = 'orderSearch',
  Loyalty = 'loyalty',
}

export const DEFAULT_SECTION = AppSection.OrderSearch;

const sections = {
  [AppSection.Loyalty]: {
    supportedBrands: brandConfig.getSupportedBrandsForLoyalty(),
  },
  [AppSection.OrderSearch]: {
    supportedBrands: brandConfig.getSupportedBrandsForOrders(),
  },
};

export const isAppSection = (section: string): section is AppSection => {
  return section in sections;
};

export const toAppSection = (section: string): AppSection => {
  return isAppSection(section) ? section : DEFAULT_SECTION;
};

export const getSupportedBrands = (section: AppSection): Brand[] => {
  return sections[section].supportedBrands;
};

export const isSupportedBrand = (section: AppSection, brand: BrandKey) => {
  return getSupportedBrands(section).some((b) => b.key === brand);
};

const useAppSection = () => {
  const isLoyalty = useMatch('/loyalty/*');
  const appSection = isLoyalty ? AppSection.Loyalty : AppSection.OrderSearch;

  const inAppSection = <T>(map: { [section in AppSection]: T }): T => {
    return map[appSection];
  };

  const isAppSection = (section: AppSection): boolean => {
    return appSection === section;
  };

  const supportedBrands = getSupportedBrands(appSection);

  return {
    appSection,
    isAppSection,
    inAppSection,
    supportedBrands,
  };
};

export default useAppSection;
