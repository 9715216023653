/**
 * Replaces empty or null values with a fallback description.
 *
 * @param {string | null | undefined | number | boolean} value - The value to check and potentially replace.
 * @param {(key: string) => string} [t] - Optional translation function for boolean values.
 * @param {string} [fallbackDescription='N/A'] - The description to use if the value is empty, null, or undefined.
 * @returns {string | number | boolean} - The original value if it is not empty, null, or undefined; otherwise, the fallback description.
 *
 */

export const emptyValueReplacement = (
  value: string | null | undefined | number | boolean,
  t?: (key: string) => string,
  fallbackDescription: string = 'N/A',
) => {
  if (typeof value === 'boolean') {
    if (t) {
      value = value ? t('generic.yes') : t('generic.no');
    } else {
      value = value ? 'Ja' : 'Nej';
    }
  }
  return value === null || value === undefined || value === '' || value === ' '
    ? fallbackDescription
    : value;
};
