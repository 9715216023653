interface ErrorInstance {
  responseJSON: {
    message: string;
  };
  description: string;
}

const IsErrorInstance = (object: any): object is ErrorInstance => {
  return object;
};

export default IsErrorInstance;
