import { performADRequest } from './request-helpers/apiHelper';
import { IPunchCardResponse } from '@model/punchcard';
import { appConfig } from '@/appConfig';
import { CustomError } from './CustomError';

const buildPunchCardQueryParams = (
  activeAt?: Date | null,
  isRewardClaimed?: boolean | null,
  isExpired?: boolean | null,
  isCommenced?: boolean | null,
  isConcluded?: boolean | null,
): URLSearchParams => {
  const queryParams = new URLSearchParams();

  if (activeAt !== null && activeAt !== undefined)
    queryParams.append('activeAt', activeAt.toISOString());
  if (isRewardClaimed !== null && isRewardClaimed !== undefined)
    queryParams.append('isRewardClaimed', String(isRewardClaimed));
  if (isExpired !== null && isExpired !== undefined)
    queryParams.append('isExpired', String(isExpired));
  if (isCommenced !== null && isCommenced !== undefined)
    queryParams.append('isCommenced', String(isCommenced));
  if (isConcluded !== null && isConcluded !== undefined)
    queryParams.append('isConcluded', String(isConcluded));

  return queryParams;
};

/**
 * Fetch punch cards for a specific customer
 *
 * @param trackingId - The tracking ID of the customer.
 * @param brand - The brand to fetch punch cards for.
 * @param activeAt - The date at which the punch card is active.
 * @param isRewardClaimed - Whether the reward has been claimed.
 * @param isExpired - Whether the punch card has expired.
 * @param isCommenced - Whether the punch card has commenced.
 * @param isConcluded - Whether the punch card has concluded.
 * @returns A promise of punch cards.
 * @throws CustomError if the request fails.
 *
 */
export const fetchPunchCards = async (
  trackingId: string,
  brand: string,
  activeAt?: Date | null,
  isRewardClaimed?: boolean | null,
  isExpired?: boolean | null,
  isCommenced?: boolean | null,
  isConcluded?: boolean | null,
): Promise<IPunchCardResponse> => {
  const queryParams = buildPunchCardQueryParams(
    activeAt,
    isRewardClaimed,
    isExpired,
    isCommenced,
    isConcluded,
  );

  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${trackingId}/punch-cards?${queryParams.toString()}`;

  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch active punch cards`);
  }
};
