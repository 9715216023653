import orange from '@mui/material/colors/orange';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import grey from '@mui/material/colors/grey';
import blue from '@mui/material/colors/blue';
import purple from '@mui/material/colors/purple';
import yellow from '@mui/material/colors/yellow';

export const getChipColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'åben':
      return { background: orange[500], color: 'white' };
    case 'leveret':
    case 'done':
      return { background: green[500], color: 'white' };
    case 'annulleret':
      return { background: red[500], color: 'white' };
    case 'forsinket':
      return { background: yellow[700], color: 'black' };
    case 'returneret':
      return { background: blue[500], color: 'white' };
    case 'erstattet':
      return { background: purple[500], color: 'white' };
    default:
      return { background: grey[500], color: 'white' };
  }
};

export const receiptChipColor = {
  LIGHT: {
    backgroundColor: yellow[500],
    color: 'black',
  },
  FULL: {
    backgroundColor: blue[500],
    color: 'white',
  },
  MERGED: {
    backgroundColor: green[500],
    color: 'white',
  },
};
