import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import DetailItem from '../../mui-helpers/DetailItem';
import { emptyValueReplacement } from '@utils/emptyValueReplacement';
import { formatDate } from '@utils/date-utils/formatDate';
import { Brand, BrandKey } from '@model/brand';

interface ProgramCardProps {
  customerBrandDetails: {
    brand: Brand;
    membershipNr: string | null;
    signupDate: string | null;
  };
  index: number;
  fetchCustomerProfile: (brandKey: BrandKey) => void;
}

const ProgramCard: React.FC<ProgramCardProps> = ({
  customerBrandDetails,
  index,
  fetchCustomerProfile,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      lg={2}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Tooltip
        title={t('customerProgram.clickToSearch', {
          brandName: customerBrandDetails.brand.label,
        })}
      >
        <Box
          key={`${customerBrandDetails.brand.key}-${index}`}
          onClick={() =>
            customerBrandDetails.brand.key &&
            fetchCustomerProfile(customerBrandDetails.brand.key)
          }
        >
          <CardMedia
            component="img"
            image={customerBrandDetails?.brand.logo}
            height={40}
            style={{
              objectFit: 'contain',
              width: 'auto',
              marginBottom: '0.4rem',
            }}
          />
          <DetailItem
            label={t('customerProgram.membershipNrLabel')}
            value={emptyValueReplacement(
              customerBrandDetails?.membershipNr,
              t,
              t('customerProgram.notEnrolled'),
            )}
          />
          <DetailItem
            label={t('customerProgram.signupDateLabel')}
            value={
              customerBrandDetails?.signupDate
                ? formatDate(customerBrandDetails.signupDate)
                : t('customerProgram.noDate')
            }
          />
          <DetailItem
            label={t('customerProgram.programNameLabel')}
            value={emptyValueReplacement(customerBrandDetails.brand.label)}
          />
        </Box>
      </Tooltip>
    </Grid>
  );
};

export default ProgramCard;
