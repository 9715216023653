/**
 * Formats a credit card number by adding a space after every 4 characters.
 *
 * This function removes any existing spaces in the input string and then
 * inserts a space after every 4 characters.
 *
 * @param {string} number - The credit card number to format.
 * @returns {string} - The formatted credit card number with spaces after every 4 characters.
 */
export const formatCardNumber = (number: string): string =>
  number
    .replace(/\s+/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
