export const handleLoyaltyResponses = (
  error: any,
  enqueueSnackbar: (message: string) => void,
  t: (key: string) => string,
) => {
  const responseErrorCode = error.response?.data?.errorCode || 0;

  let messageShown = ' ';
  const NO_GIGYA_PROFILE = 40410;
  const NO_ORG_ON_PROFILE = 40411;
  const NO_LOYALTY_PROFILE = 40420;
  const PROFILE_CONFLICT = 40001;
  const GATEWAY_TIMEOUT = 50001;
  const BAD_REQUEST = 40000;
  const INTERNAL_SERVER_ERROR = 50000;
  switch (responseErrorCode) {
    case NO_GIGYA_PROFILE: {
      messageShown = t('errorCode.noGigyaProfile');
      break;
    }
    case NO_ORG_ON_PROFILE: {
      messageShown = t('errorCode.noOrgOnProfile');
      break;
    }
    case NO_LOYALTY_PROFILE: {
      messageShown = t('errorCode.noLoyaltyProfile');
      break;
    }
    case PROFILE_CONFLICT: {
      messageShown = t('errorCode.profileConflict');
      break;
    }
    case GATEWAY_TIMEOUT: {
      messageShown = t('errorCode.gatewayTimeout');
      break;
    }
    case BAD_REQUEST: {
      messageShown = t('errorCode.badRequest');
      break;
    }
    case INTERNAL_SERVER_ERROR: {
      messageShown = t('errorCode.internalServerError');
      break;
    }
  }
  enqueueSnackbar(messageShown);
};
