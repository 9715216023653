import isErrorInstance from '../error-handler/IsErrorInstance';
import { enqueueSnackbar } from 'notistack';
import { OrderService } from '@/service/OrderService';
import { IOrderResult } from '@model/order';
import { checkInputType } from './checkInputType';

export const orderFetcherUtil = async (
  input: string | number,
  brand: string,
) => {
  if (input) {
    try {
      const inputType = checkInputType(input);
      return fillTableByIdentifier(input, inputType, brand);
    } catch (error: unknown) {
      if (error && isErrorInstance(error)) {
        enqueueSnackbar(
          error.responseJSON ? error.responseJSON.message : 'Ukendt fejl',
          {
            variant: 'error',
            autoHideDuration: 4000,
          },
        );
      }
      console.error(error);
    }
  }
};

const fillTableByIdentifier = async (
  input: string | number,
  identifier: string,
  brand: string,
) => {
  const orderService: OrderService = new OrderService();
  if (identifier === 'orderId') {
    return orderService.getShortOrderDetails({ orderId: input, brand: brand });
  } else {
    const orders: IOrderResult[] = await orderService.getOrderList({
      identifier: identifier,
      brand: brand,
      input: input,
    });
    if (orders.length === 0) {
      enqueueSnackbar(`Ingen ordre fundet i ${brand} til ${input}`);
    }
    return orders;
  }
};
