import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { arrayRemove, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { SearchType } from '@utils/checkInputType';
import appState from '@/state';
import { BrandKey } from '@model/brand';

export const useFirestoreTicketListener = (
  handleFetchCustomerDetails: (
    inputQuery: string,
    brand: BrandKey,
    searchType: SearchType,
  ) => void,
) => {
  const { instance } = useMsal();
  const [open, setOpen] = useState(false);
  const [ticketId, setTicketId] = useState<string | number>('');
  const [input, setInput] = useState<string>('');

  // Use of `unknown` type is deliberate; in order to delete from an array in
  // Firestore, we need to pass the exact same object that's in it, so we can't
  // assume anything and want to prevent modifying the object in any way
  const [ticketData, setTicketData] = useState<unknown>(null);

  const accountId = instance.getActiveAccount()?.localAccountId;

  const { t } = useTranslation();

  useEffect(() => {
    if (!accountId) {
      console.error('No account ID; cannot listen for tickets');
      return;
    }

    const docRef = doc(db, 'users', accountId);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        const data = snapshot.data();
        const ticket = data?.tickets?.[data.tickets.length - 1];
        setTicketData(ticket);

        const input =
          ticket?.orderNumber ||
          ticket?.requesterEmail ||
          ticket?.requesterPhoneNumber;

        if (snapshot.metadata.hasPendingWrites) {
          return;
        }

        if (!input) {
          clearCurrentTicket();
          return;
        }

        appState.customer.brand.value = ticket?.brandName;
        appState.customer.email.value = ticket?.requesterEmail;
        setTicketId(ticket?.ticketId);
        setInput(input);
        setOpen(true);
      },
      (error) => {
        console.error('Error getting document From firestore: ', error);
      },
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
    // Reasonable to ignore because we don't want to include the setter of the context in the dependency array
  }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function clearCurrentTicket() {
    if (!accountId || !ticketData) {
      return;
    }

    const docRef = doc(db, 'users', accountId);

    try {
      await updateDoc(docRef, {
        tickets: arrayRemove(ticketData),
      });
    } catch (error) {
      console.error('Error clearing ticket from Firestore', error);
    }
  }

  const handleOk = async () => {
    if (ticketData && appState.customer.brand.value) {
      handleFetchCustomerDetails(
        input,
        appState.customer.brand.value,
        SearchType.EMAIL,
      );
    }

    setOpen(false);
    await clearCurrentTicket();
  };

  const handleClose = async () => {
    setOpen(false);
    await clearCurrentTicket();
  };

  return () => {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('ticketDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('ticketDialog.newTicketFound', {
              brand: appState.customer.brand.value,
              id: ticketId,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  };
};
