import React from 'react';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import { blue, brown, green, red } from '@mui/material/colors';
import { formatDate } from '@utils/date-utils/formatDate';
import { useTranslation } from 'react-i18next';
import { MemberOffer } from '@model/offer';
import Chip from '@mui/material/Chip';
import { isOfferMembership } from '@utils/isOfferMembership';
import appState from '@/state';
import brandConfig from '@/brandConfig';

interface OfferListProps {
  offers?: MemberOffer[];
  selectedOffer: MemberOffer | null;
  setSelectedOffer: (offer: MemberOffer) => void;
  setSearchTerm: (term: string) => void;
  filterOffers: (offers: MemberOffer[]) => MemberOffer[];
}

const OfferList: React.FC<OfferListProps> = ({
  offers,
  selectedOffer,
  setSelectedOffer,
  setSearchTerm,
  filterOffers,
}) => {
  const customerBrand = appState.customer.brand.value;
  const { t } = useTranslation();

  const handleOfferClick = (offer: MemberOffer) => {
    setSelectedOffer(offer);
  };

  /**
   * Generates a Chip component based on the provided offer item.
   *
   * @param {MemberOffer} offerItem - The offer item to generate the Chip for.
   * @returns {JSX.Element | null} - A Chip component if the offer is active and meets the criteria, otherwise null.
   *
   * The function performs the following steps:
   * 1. Checks if the offer item is active. If not, returns null.
   * 2. Determines if the offer is a membership offer.
   * 3. If the offer is personal offer(not a membership offer),
   * or it is a membership offer and the brand is in the `membershipOffersBurnableBrands` list:
   *    a. Determines the redeem limit and redeem count, defaulting to 99999(no limit) and 0 respectively if not provided.
   *    b. Checks if the offer is exhausted by comparing the redeem count to the redeem limit.
   *    c. Checks if the offer is expired by comparing the current date to the offer's validUntil date.
   *    d. Creates a Chip component with appropriate label and styles based on whether the offer is exhausted.
   */
  const generateChip = (offerItem: MemberOffer): JSX.Element | null => {
    if (!offerItem.isActive) return null;

    if (customerBrand) {
      const isMembershipOffer = isOfferMembership(offerItem.offer);
      const isBurnableBrand =
        brandConfig.getBrand(customerBrand).areMemberOffersBurnable;

      if (!isMembershipOffer || (isMembershipOffer && isBurnableBrand)) {
        const redeemLimit = offerItem.redeemLimit ?? 99999;
        const redeemCount = offerItem.redeemCount ?? 0;
        const isExhausted = redeemCount >= redeemLimit;

        const expirationDate: Date = new Date(offerItem.offer.validUntil);
        const today: Date = new Date();

        if (
          today.getFullYear() >= expirationDate.getFullYear() &&
          today.getMonth() >= expirationDate.getMonth() &&
          today.getDate() > expirationDate.getDate()
        ) {
          return (
            <Box display="flex" justifyContent="flex-end" flexGrow={1}>
              <Chip
                label={t('offerDetails.expired')}
                sx={{
                  ml: 1,
                  bgcolor: red[500],
                  color: 'white',
                }}
              />
            </Box>
          );
        } else {
          return (
            <Box display="flex" justifyContent="flex-end" flexGrow={1}>
              <Chip
                label={t(
                  isExhausted
                    ? 'offerDetails.exhausted'
                    : 'offerDetails.activated',
                )}
                sx={{
                  ml: 1,
                  bgcolor: isExhausted ? brown[500] : 'success.main',
                  color: 'white',
                }}
              />
            </Box>
          );
        }
      }
    }

    return null;
  };

  return (
    <List>
      <TextField
        fullWidth
        label={t('offerDetails.search')}
        variant="outlined"
        margin="normal"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Box
        sx={{
          maxHeight: '65.5vh',
          overflow: 'auto',
        }}
      >
        {offers &&
          offers.length > 0 &&
          filterOffers(offers).map((offerItem) => (
            <ListItem
              key={offerItem.offer.id}
              onClick={() => handleOfferClick(offerItem)}
              sx={{
                bgcolor:
                  selectedOffer?.offer.id === offerItem.offer.id
                    ? 'action.selected'
                    : 'inherit',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                cursor: 'pointer',
                padding: '0.1rem',
              }}
            >
              <Box display="flex" alignItems="center" width="100%">
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center">
                      <ListItemIcon
                        sx={{ minWidth: '1rem', marginRight: '0.2rem' }}
                      >
                        {isOfferMembership(offerItem.offer) ? (
                          <GroupIcon
                            sx={{ color: blue[500], fontSize: '2rem' }}
                          />
                        ) : (
                          <PersonIcon
                            sx={{ color: green[500], fontSize: '2rem' }}
                          />
                        )}
                      </ListItemIcon>
                      {offerItem.offer.title ?? offerItem.offer.name}
                      {generateChip(offerItem)}
                    </Box>
                  }
                  secondary={`${formatDate(offerItem.offer.validFrom)} - ${formatDate(offerItem.offer.validUntil)}`}
                />
              </Box>
            </ListItem>
          ))}
      </Box>
    </List>
  );
};

export default OfferList;
