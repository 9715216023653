import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { ICheckboxOption, IFilters } from './IFilters';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import 'dayjs/locale/da';

interface FilterViewProps {
  dateType: 'validAt' | 'activeAt';
  dateValue: Dayjs | null;
  checkboxes: ICheckboxOption[];
  handleApplyFilters: () => void;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  onClearFilters: () => void;
}

const gridStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 1,
  width: '100%',
  marginBottom: '1.4rem',
};

const localeMap: { [key: string]: string } = {
  en: 'en',
  da: 'da',
  de: 'de',
  pl: 'pl',
};

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomFilterOptions: React.FC<FilterViewProps> = ({
  dateType,
  dateValue,
  checkboxes,
  handleApplyFilters,
  setFilters,
  onClearFilters,
}) => {
  const { i18n, t } = useTranslation();
  const currentLocale = localeMap[i18n.language] || localeMap['en'];
  dayjs.locale(currentLocale);

  const setDateValue = (date: Dayjs | null) => {
    setFilters((prev: IFilters) => ({ ...prev, [dateType]: date }));
  };

  const handleClearFilter = useCallback(() => {
    const resetFilters = checkboxes.reduce(
      (acc, checkbox) => ({ ...acc, [checkbox.key]: false }),
      {} as { [key: string]: boolean },
    );

    setFilters({ [dateType]: null, dateValue: null, ...resetFilters });
    onClearFilters();
  }, [checkboxes, setFilters, dateType, onClearFilters]);

  const handleCheckboxChange = (key: string, checked: boolean) => {
    setFilters((prevFilters: IFilters) => ({
      ...prevFilters,
      [key]: checked ? true : null,
    }));
  };

  const renderFormControlLabels = () => {
    return checkboxes.map(({ checked, key, label }) => (
      <FormControlLabel
        sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.8rem' } }}
        key={key}
        control={
          <Checkbox
            checked={checked ? true : false} // undefined is not a valid value for checkboxes without causing errors and the API accepts, boolean and undefined.
            onChange={(e) => handleCheckboxChange(key, e.target.checked)}
          />
        }
        label={label}
      />
    ));
  };

  const areAnyCheckboxesSelected = checkboxes.some(
    (checkbox) => checkbox.checked,
  );

  const renderClearFilterButton = () => (
    <Button
      variant="outlined"
      onClick={handleClearFilter}
      size="medium"
      endIcon={<CloseIcon fontSize="small" />}
      disabled={areAnyCheckboxesSelected || dateValue ? false : true}
    >
      {t('punchCard.clearFilter')}
    </Button>
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={5} sx={gridStyles}>
        <Box sx={{ flex: 'shrink' }}>{renderFormControlLabels()}</Box>
      </Grid>
      <Grid item xs={12} sm={7} sx={gridStyles}>
        <Box sx={{ flex: 'grow' }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={currentLocale}
          >
            <DatePicker
              label={t('punchCard.activeDate')}
              value={dateValue}
              slotProps={{ textField: { size: 'small' } }}
              onChange={setDateValue}
              timezone="UTC"
            />
          </LocalizationProvider>
        </Box>
        <Button
          variant="contained"
          size="medium"
          endIcon={<SearchIcon />}
          onClick={handleApplyFilters}
        >
          {t('generic.applyFilter')}
        </Button>
        {renderClearFilterButton()}
      </Grid>
    </Grid>
  );
};

export default CustomFilterOptions;
