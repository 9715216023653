import { enqueueSnackbar } from 'notistack';
import { t } from 'i18next';

export const copyToClipboard = async (copiedValue: string) => {
  await navigator.clipboard.writeText(copiedValue);
  return enqueueSnackbar(t('messages.copyToClipboard'), {
    variant: 'success',
    autoHideDuration: 2000,
  });
};
