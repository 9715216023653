import React from 'react';
import OrderTable from './search-result-table/OrderTable';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useNavigate } from 'react-router-dom';
import Loader from '../mui-helpers/Loader';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const OrderSearch = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        onClick={() => {
          navigate('/stocks');
        }}
      >
        Lagerstatus
        <Box
          component="span"
          sx={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}
        >
          <LocalShippingOutlinedIcon />
        </Box>
      </Button>
      <Loader area="order-list" />
      <Loader area="full-order-details" />
      <OrderTable />
    </>
  );
};

export default OrderSearch;
