import { enqueueSnackbar } from 'notistack';

const errorHandle = (notificationMessage: string, errorMessage: string) => {
  enqueueSnackbar(notificationMessage, {
    variant: 'error',
    autoHideDuration: 4000,
  });
  throw new Error(errorMessage);
};

export const requestValidation = (articleId: string, siteIds: string[]) => {
  if (parseInt(articleId) < 0) {
    errorHandle(
      'Negative tal er ikke tilladt!',
      'Negative numbers are not allowed!',
    );
  } else if (Number.isNaN(parseInt(articleId))) {
    errorHandle(
      'Ikke muligt at søge på tom Artikelnr',
      'Not possible to search by empty ArticleNo',
    );
  }
  if (siteIds === undefined || siteIds[0] === undefined) {
    errorHandle('Ingen butikker valgt', 'No shops selected');
  } else if (siteIds.length > 120) {
    errorHandle(
      'Vælg venligst mindre end 120 butikker',
      'More than 120 stores selected',
    );
  }
};
