import { Client } from '@microsoft/microsoft-graph-client';
import { DirectoryObject } from '@microsoft/microsoft-graph-types';

export interface Group extends DirectoryObject {
  '@odata.type': '#microsoft.graph.group';
  appRoleId: string;
}

interface MemberOfResponse {
  value: Group[];
}

export const callMsGraph = async (accessToken: string): Promise<Group[]> => {
  const client = Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    },
  });

  const response = await client
    .api('/me/appRoleAssignments')
    .version('v1.0')
    .get();
  const data = response as MemberOfResponse;
  return data.value;
};
