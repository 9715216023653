import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { copyToClipboard } from '@utils/copyToClipboard';
import Link from '@mui/material/Link';

import {
  ScrollableCardContent,
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '../../../mui-helpers/customComponentStyles';
import { IProduct } from '@model/order';

interface TrackingDetailsProps {
  tracking: IProduct['tracking'];
}

const ProductTracking: React.FC<TrackingDetailsProps> = ({ tracking }) => {
  return (
    <StyledBorderCard variant="outlined">
      <ScrollableCardContent sx={{ flex: 1 }}>
        <StyledTitle>Tracking Details</StyledTitle>
        {tracking.trackingIds.length === 0 &&
        tracking.trackingUrls.length === 0 ? (
          <StyledDescription>N/A</StyledDescription>
        ) : (
          <>
            {tracking.trackingIds.map((trackingId, index) => (
              <Tooltip
                key={`${index}-${trackingId}`}
                placement="bottom"
                title="Klik for at kopiere"
              >
                <StyledDescription
                  onClick={() => copyToClipboard(trackingId)}
                  style={{ cursor: 'pointer', display: 'inline-block' }}
                >
                  {trackingId}
                </StyledDescription>
              </Tooltip>
            ))}
            {tracking.trackingUrls.map((trackingUrl, index) => (
              <Tooltip
                key={`${index}-${trackingUrl}`}
                placement="bottom"
                title="Klik for at åbne"
              >
                <Link
                  href={trackingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'block' }}
                >
                  Tracking Url
                </Link>
              </Tooltip>
            ))}
          </>
        )}
      </ScrollableCardContent>
    </StyledBorderCard>
  );
};

export default ProductTracking;
